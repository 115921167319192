import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../config/apiConfig";
import {
	SimpleGrid,
	Flex,
	Box,
	chakra,
	Button,
	Badge,
	Container,
	Text,
	Image,
	Heading,
	ListItem,
	UnorderedList,
	Icon,
	Stack,
	HStack,
	VStack,
	Link,
	useToast,
} from "@chakra-ui/react";
import { CheckIcon, ChevronRightIcon } from "@chakra-ui/icons";
import restaurantImg from "../images/restaurant.jpg";
import condominiumImg from "../images/condominium.jpg";
import CarouselsHome from "./component/CarouselsHome";
import Testimonials from "./component/Testimonials";
import Statistics from "./component/Statistics";
import BusinessList from "./component/BusinessList";
import Hero from "./component/Hero";
import CardRegister from "./component/CardRegister";
import CardPrice from "./component/CardPrice";

import res_system_1 from "../images/pmanual/res_system_1.png";
import res_system_2 from "../images/pmanual/res_system_2.png";
import res_system_3 from "../images/pmanual/res_system_3.png";
import res_system_4 from "../images/pmanual/res_system_4.png";

function Home() {
	const toast = useToast();
	const boxRef = useRef(null);

	const scrollToBox = () => {
		if (boxRef.current) {
			const yOffset = -50; // Adjust for the -30px
			const yPosition =
				boxRef.current.getBoundingClientRect().top +
				window.pageYOffset +
				yOffset;

			window.scrollTo({ top: yPosition, behavior: "smooth" });
		}
	};

	const [price, setPrice] = useState();
	const [priceCount, setPriceCount] = useState();
	useEffect(() => {
		const getPricePromotion = async () => {
			try {
				const response = await axios.get(
					`${API_BASE_URL}/api/marketing/price_promotion`
				);
				setPrice(response.data.results[0].price);
				setPriceCount(response.data.results[0].count);
			} catch (error) {
				toast({
					title: `ไม่สามารถติดต่อระบบได้`,
					description: `กรุณาติดต่อพนักงาน`,
					position: "top",
					isClosable: true,
					status: "error",
					variant: "top-accent",
					duration: 5000,
				});
			}
		};
		getPricePromotion();
	}, [toast]);

	return (
		<>
			<Box>
				<CarouselsHome />
				<Hero scrollToBox={scrollToBox} price={price} />
				<ProductFeature />
				<Why4Code />
				<Box ref={boxRef}>
					<BusinessList />
				</Box>
				<Statistics />
				<NotUser />
				<BusinessCategory />
				<Testimonials />
				<CardPrice
					businessType={"restaurant"}
					price={price}
					priceCount={priceCount}
				/>
				<CardRegister price={price} />
			</Box>
		</>
	);
}

const BusinessCategory = () => {
	const navigate = useNavigate();
	const sections = [
		{
			title: "ระบบจัดการออเดอร์",
			description:
				"ระบบสั่งอาหารผ่าน QR Menu เพิ่มความยืดหยุ่นในการอัปเดตเมนู ลดการใช้กระดาษ ประหยัดเวลาและลดความผิดพลาดด้วยระบบดิจิทัล ใช้งานง่าย ใช้งานได้ทันทีไม่ต้องติดตั้งอุปกรณ์เสริม ช่วยให้การจัดการออเดอร์รวดเร็วและมีประสิทธิภาพ เพิ่มยอดขายได้จริง ตอบโจทย์ความต้องการของลูกค้า มอบประสบการณ์การสั่งอาหารที่สะดวกและประทับใจ",
			badges: [
				"ร้านอาหาร",
				"สวนอาหาร",
				"ลานเบียร์",
				"ร้านคาเฟ่เบเกอรี่",
				"ร้านบุฟเฟต์",
			],
			img: restaurantImg,
			button: { text: "รายละเอียด", action: () => navigate("/restaurant") },
		},
		{
			title: "ระบบบริการสำหรับธุรกิจที่พัก",
			description:
				"หากคุณต้องการเพิ่มรายได้ให้กับกิจการด้วยการนำเสนอบริการเสริมที่มี เช่น บริการอาหาร เครื่องดื่ม ขนมขบเคี้ยว บริการสปาและนวด บริการเช่ารถ บริการทำความสะอาด บริการซักรีด บริการรับ-ส่ง ขายของที่ระลึกและอื่นๆ",
			badges: [
				"รีสอร์ท",
				"โรงแรม",
				"หอพัก",
				"วิลล่า",
				"บังกะโล",
				"โฮมสเตย์",
				"เกสต์ เฮาส์",
				"อพาร์ทเม้นต์",
				"โมเทล",
			],
			img: restaurantImg,
			button: { text: "รายละเอียด", action: () => navigate("/accommodation") },
		},
		{
			title: "ระบบขายสินค้า/บริการ",
			description:
				"สำหรับผู้ประกอบกิจการที่ทราบสถานที่ปลายทางที่จะนำส่งบริการ สามารถนำระบบไปต่อยอดบริการด้วยความสามารถปรับเปลี่ยนสินค้าหรือบริการได้อย่างยืดหยุ่น อัพเดทแบบทันที บันทึกออเดอร์ให้อัตโนมัติและแจ้งเตือนส่งตรงถึง LINE ผู้ประกอบการทันที",
			badges: [
				"ร้านค้าใน/ใกล้หอพัก",
				"ร้านซักอบรีด",
				"ร้านรับออเดอร์ขนม",
				"ธุรกิจรับหิ้วสินค้า",
				"เช่าที่นั่งร่วมกับสั่งอาหาร",
				"ธุรกิจรับเสริมสวย",
				"ร้านอาหาร/เครื่องดื่มที่มีบริการจัดส่ง",
			],
			img: condominiumImg,
			button: { text: "เร็วๆ นี้", action: () => {}, disabled: true },
		},
	];
	return (
		<Container maxW="container.xl" display={"none"}>
			<Flex
				pt={{ base: 10, md: 20 }}
				pb={{ base: 6, md: 12 }}
				direction={"column"}
				gap={{ base: 10, md: 20 }}
				bg="white"
				_dark={{ bg: "gray.800" }}
				mx="auto"
			>
				{sections.map((section, index) => (
					<SimpleGrid
						key={index}
						alignItems="center"
						columns={{ base: 1, md: 2 }}
						spacingY={{ base: 5, md: 32 }}
						spacingX={{ md: 10, lg: 24 }}
					>
						<Box
							w="full"
							h="full"
							py={48}
							bg="gray.200"
							bgImage={section.img}
							bgSize="cover"
							bgPosition="center"
							bgRepeat="no-repeat"
							borderRadius={"2em"}
							filter={"revert-layer"}
						></Box>
						<Box>
							<chakra.h2
								mb={4}
								fontSize={{ base: "2xl", md: "4xl" }}
								fontWeight="600"
								textAlign={{ base: "center", md: "left" }}
								color="cyan.700"
								_dark={{ color: "gray.400" }}
								lineHeight={{ md: "shorter" }}
							>
								{section.title}
							</chakra.h2>
							<chakra.p
								mb={{ base: 2, md: 5 }}
								textAlign={{ base: "center", sm: "left" }}
								color="gray.600"
								_dark={{ color: "gray.400" }}
								fontSize={{ md: "lg" }}
							>
								<Box mb={2}>
									{section.badges.map((badge, i) => (
										<Badge
											key={i}
											mr={2}
											fontSize={"sm"}
											fontWeight={"500"}
											colorScheme="blackAlpha"
										>
											{badge}
										</Badge>
									))}
								</Box>
								{section.description}
							</chakra.p>
							<Box textAlign={{ base: "center", md: "left" }}>
								<Button
									size={{ base: "md", md: "lg" }}
									fontWeight={"normal"}
									bgColor={"cyan.700"}
									color="white"
									_hover={{ bgColor: "cyan.800" }}
									onClick={section.button.action}
									isDisabled={section.button.disabled}
								>
									{section.button.text}
								</Button>
							</Box>
						</Box>
					</SimpleGrid>
				))}
			</Flex>
		</Container>
	);
};

const Why4Code = () => {
	return (
		<Box
			py={{ base: 10, md: 12, lg: 14, xl: 16 }}
			mx="auto"
			_dark={{ bg: "gray.700" }}
			bgGradient="linear(to-r, cyan.400, orange.200)"
		>
			<Container maxW="container.xl">
				<Box mx="auto">
					<chakra.h1
						mb={{ base: 3, md: 6 }}
						fontSize={{
							base: "3xl",
							md: "5xl",
						}}
						fontWeight="bold"
						lineHeight={{ base: 1.4, md: "none" }}
						letterSpacing={{
							base: "normal",
							md: "tight",
						}}
						color="gray.900"
						_dark={{
							color: "gray.100",
						}}
						textAlign={"center"}
					>
						<Text
							display={{
								base: "block",
								md: "inline",
							}}
							w="full"
							bgClip="text"
							bgGradient="linear(to-r, purple.500,red.500)"
							fontWeight="extrabold"
						>
							ลักษณะการนำไปใช้งาน
						</Text>
					</chakra.h1>
					<SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
						<HStack align={"top"}>
							<Box color={"green.400"} px={2}>
								<Icon as={CheckIcon} />
							</Box>
							<VStack align={"start"}>
								<Text fontWeight={600}>ร้านที่จ่ายก่อนกิน</Text>
								<Text color={"gray.600"} _dark={{ color: "gray.400" }}>
									ระบบขายหน้าร้าน POS นำไปใช้บันทึกรายการสั่งซื้อ
									สรุปภาพรวมแต่ละวัน พร้อมแสดงรายการสินค้าขายดี
								</Text>
							</VStack>
						</HStack>
						<HStack align={"top"}>
							<Box color={"green.400"} px={2}>
								<Icon as={CheckIcon} />
							</Box>
							<VStack align={"start"}>
								<Text fontWeight={600}>ร้านที่กินก่อนจ่าย</Text>
								<Text color={"gray.600"} _dark={{ color: "gray.400" }}>
									ลูกค้าสแกน QR Menu สั่งอาหารเอง ลดการสัมผัส
									เหมาะสำหรับร้านที่มีพนักงานจำกัด
								</Text>
							</VStack>
						</HStack>
						<HStack align={"top"}>
							<Box color={"green.400"} px={2}>
								<Icon as={CheckIcon} />
							</Box>
							<VStack align={"start"}>
								<Text fontWeight={600}>แบบผสมผสานการใช้งาน</Text>
								<Text color={"gray.600"} _dark={{ color: "gray.400" }}>
									พนักงานรับออเดอร์ก่อนและแนะนำลูกค้าหากต้องการอาหารเพิ่มเติมสามารถสแกนสั่งเองได้
								</Text>
							</VStack>
						</HStack>
					</SimpleGrid>
				</Box>
			</Container>
		</Box>
	);
};

const ProductFeature = () => {
	const navigate = useNavigate();

	const Feature = (props) => {
		return (
			<Box>
				<Flex
					alignItems="center"
					justifyContent="center"
					w={8}
					h={8}
					mb={4}
					rounded="full"
					color={`${props.color}.600`}
					_dark={{
						color: `${props.color}.100`,
					}}
					bg={`${props.color}.100`}
				>
					<Icon
						boxSize={5}
						viewBox="0 0 20 20"
						fill="currentColor"
						aria-hidden="true"
					>
						{props.icon}
					</Icon>
				</Flex>
				<chakra.h3
					mb={2}
					fontWeight="semibold"
					lineHeight="shorter"
					_light={{
						color: "gray.900",
					}}
				>
					{props.title}
				</chakra.h3>
				<chakra.p
					fontSize="sm"
					color="gray.500"
					_dark={{
						color: "gray.400",
					}}
				>
					{props.children}
				</chakra.p>
			</Box>
		);
	};
	const Feature2 = (props) => {
		return (
			<Box p={10}>
				<Image src={props.img} mb={2} />
				<Heading
					as={"h3"}
					mb={{ base: 1, md: 3 }}
					fontSize="lg"
					lineHeight="shorter"
					fontWeight="bold"
					_light={{
						color: "cyan.700",
					}}
				>
					{props.title}
				</Heading>
				<Text
					lineHeight="tall"
					color="gray.600"
					_dark={{
						color: "gray.400",
					}}
				>
					{props.children}
				</Text>
			</Box>
		);
	};

	return (
		<Container maxW="container.xl" py={20}>
			<Flex w="auto" justifyContent="center" alignItems="center">
				<Box
					mx="auto"
					bg="white"
					_dark={{
						bg: "gray.800",
					}}
				>
					<Box textAlign={"center"}>
						<chakra.p
							fontSize={{
								base: "3xl",
								sm: "4xl",
							}}
							lineHeight="8"
							fontWeight="extrabold"
							letterSpacing="tight"
							_light={{
								color: "gray.900",
							}}
						>
							ฟังก์ชั่นการใช้งาน
						</chakra.p>
						<chakra.p
							mt={2}
							maxW="2xl"
							fontSize="xl"
							mx={{
								lg: "auto",
							}}
							color="gray.500"
							_dark={{
								color: "gray.400",
							}}
						>
							ระบบใช้งานง่ายไม่ซับซ้อน เริ่มใช้งานได้ไม่กี่ขั้นตอน
						</chakra.p>
						<Flex
							align="center"
							justify="center"
							direction="row"
							wrap="wrap"
							gap={2}
						>
							<Box fontWeight={"800"}>สมัครใช้งาน</Box>
							<ChevronRightIcon color={"gray.400"} fontSize={"xl"} />
							<Box fontWeight={"800"}>ลงสินค้า</Box>
							<ChevronRightIcon color={"gray.400"} fontSize={"xl"} />
							<Box fontWeight={"800"}>เพิ่ม line token</Box>
							<ChevronRightIcon color={"gray.400"} fontSize={"xl"} />
							<Box fontWeight={"800"}>ติดตั้ง QR Menu</Box>
						</Flex>
					</Box>
					<Box
						bg="white"
						_dark={{
							bg: "gray.800",
						}}
						mx="auto"
						mt={10}
					>
						<SimpleGrid
							p={{ base: 2, md: 10 }}
							alignItems="center"
							columns={{
								base: 1,
								md: 2,
							}}
							flexDirection="column-reverse"
							spacingY={{
								base: 10,
								md: 32,
							}}
							spacingX={{
								base: 10,
								md: 24,
							}}
							borderBottom={"1px solid #e4e4e4"}
						>
							<Box
								order={{
									base: "initial",
									md: 2,
								}}
							>
								<chakra.h2
									mb={4}
									fontSize={{
										base: "2xl",
										md: "4xl",
									}}
									fontWeight="extrabold"
									letterSpacing="tight"
									textAlign={{
										base: "center",
										md: "left",
									}}
									color="cyan.600"
									_dark={{
										color: "gray.400",
									}}
									lineHeight={{
										md: "shorter",
									}}
								>
									แอดมินจัดการร้าน
								</chakra.h2>
								<chakra.p
									mb={5}
									color="gray.600"
									_dark={{
										color: "gray.400",
									}}
									fontSize={{
										md: "lg",
									}}
								>
									ส่วนจัดการข้อมูลหลังบ้านสำหรับปรับแต่งรายละเอียดต่างๆ
									<UnorderedList pl={{ base: 2, md: 10 }}>
										<ListItem>สถิติข้อมูลการขาย</ListItem>
										<ListItem>ปรับแต่งชื่อหมวดหมู่สินค้า</ListItem>
										<ListItem>ปรับแต่งรายละเอียดสินค้า</ListItem>
										<ListItem>ปรับเปลี่ยนชื่อร้าน โลโก้ร้าน</ListItem>
										<ListItem>โหลด QR Menu พร้อมตัวอย่าง</ListItem>
										<ListItem>ระบุแจ้งเตือน LINE Notify Token</ListItem>
										<ListItem>ข้อมูลใช้งานสำหรับพนักงาน</ListItem>
									</UnorderedList>
								</chakra.p>
							</Box>
							<Box w="full" h="full">
								<Image src={res_system_1} boxSize="100%" objectFit="cover" />
							</Box>
						</SimpleGrid>
						<SimpleGrid
							p={{ base: 2, md: 10 }}
							alignItems="center"
							columns={{
								base: 1,
								md: 2,
							}}
							spacingY={{
								base: 10,
								md: 32,
							}}
							spacingX={{
								base: 10,
								md: 24,
							}}
							borderBottom={"1px solid #e4e4e4"}
						>
							<Box>
								<chakra.h2
									mb={4}
									fontSize={{
										base: "2xl",
										md: "4xl",
									}}
									fontWeight="extrabold"
									letterSpacing="tight"
									textAlign={{
										base: "center",
										md: "left",
									}}
									color="orange.300"
									_dark={{
										color: "gray.400",
									}}
									lineHeight={{
										md: "shorter",
									}}
								>
									ใช้งาน QR Menu
								</chakra.h2>
								<chakra.p
									mb={5}
									color="gray.600"
									_dark={{
										color: "gray.400",
									}}
									fontSize={{
										md: "lg",
									}}
								>
									<UnorderedList pl={{ base: 2, md: 10 }}>
										<ListItem>ลูกค้าสั่งสินค้าได้ด้วยตัวเอง</ListItem>
										<ListItem>เพิ่มโอกาสในการสั่งซื้อ</ListItem>
										<ListItem>ลดการยื่นรอรับออเดอร์ของพนักงาน</ListItem>
										<ListItem>เช็ครายการสั่งซื้อทั้งหมดก่อนจ่ายเงิน</ListItem>
										<ListItem>กดเรียกพนักงานผ่านระบบ</ListItem>
									</UnorderedList>
								</chakra.p>
							</Box>
							<Box w="full" h="full">
								<Image src={res_system_2} boxSize="100%" objectFit="cover" />
							</Box>
						</SimpleGrid>
						<SimpleGrid
							p={{ base: 2, md: 10 }}
							alignItems="center"
							columns={{
								base: 1,
								md: 2,
							}}
							flexDirection="column-reverse"
							spacingY={{
								base: 10,
								md: 32,
							}}
							spacingX={{
								base: 10,
								md: 24,
							}}
							borderBottom={"1px solid #e4e4e4"}
						>
							<Box
								order={{
									base: "initial",
									md: 2,
								}}
							>
								<chakra.h2
									mb={4}
									fontSize={{
										base: "2xl",
										md: "4xl",
									}}
									fontWeight="extrabold"
									letterSpacing="tight"
									textAlign={{
										base: "center",
										md: "left",
									}}
									color="green.500"
									_dark={{
										color: "gray.400",
									}}
									lineHeight={{
										md: "shorter",
									}}
								>
									แจ้งรายการผ่าน LINE
								</chakra.h2>
								<chakra.p
									mb={5}
									color="gray.600"
									_dark={{
										color: "gray.400",
									}}
									fontSize={{
										md: "lg",
									}}
								>
									สามารถกำหนดให้ระบบส่งคำสั่งผ่าน Line Notify
									ไปหาแอดมินเพียงคนเดียวหรือจะสร้างกลุ่มไว้สำหรับส่งคำสั่งให้ทุกคนหรือทุกส่วนงานทราบพร้อมกันได้
									<UnorderedList pl={{ base: 2, md: 10 }}>
										<ListItem>แจ้งรายการสั่งซื้อทั้งหมดมาที่ไลน์</ListItem>
										<ListItem>แจ้งรายละเอียดโต๊ะหรือห้องไหนสั่งซื้อ</ListItem>
										<ListItem>
											สั่งชื่อสินค้าอะไร จำนวนเท่าไหร่ พร้อมสรุปราคาให้ได้
										</ListItem>
									</UnorderedList>
								</chakra.p>
								<chakra.p
									mb={5}
									color="gray.600"
									_dark={{
										color: "gray.400",
									}}
									fontSize={"sm"}
								>
									<Link href="https://line.me/" color={"green"} isExternal>
										Download LINE
									</Link>
								</chakra.p>
							</Box>
							<Box w="full" h="full">
								<Image src={res_system_3} boxSize="100%" objectFit="cover" />
							</Box>
						</SimpleGrid>
						<SimpleGrid
							p={{ base: 2, md: 10 }}
							alignItems="center"
							columns={{
								base: 1,
								md: 2,
							}}
							spacingY={{
								base: 10,
								md: 32,
							}}
							spacingX={{
								base: 10,
								md: 24,
							}}
						>
							<Box>
								<chakra.h2
									mb={4}
									fontSize={{
										base: "2xl",
										md: "4xl",
									}}
									fontWeight="extrabold"
									letterSpacing="tight"
									textAlign={{
										base: "center",
										md: "left",
									}}
									color="pink.300"
									_dark={{
										color: "gray.400",
									}}
									lineHeight={{
										md: "shorter",
									}}
								>
									จดออเดอร์บนมือถือ
								</chakra.h2>
								<chakra.p
									mb={5}
									color="gray.600"
									_dark={{
										color: "gray.400",
									}}
									fontSize={{
										md: "lg",
									}}
								>
									ช่วยพนักงานบันทึกออเดอร์ลงระบบ
									<UnorderedList pl={{ base: 2, md: 10 }}>
										<ListItem>กรณีที่ลูกค้าไม่สะดวกใช้ QR Menu</ListItem>
										<ListItem>บันทึกออเดอร์ลงระบบจากมือถือ</ListItem>
										<ListItem>พนักงานตรวจสอบรายการจากมือถือ</ListItem>
										<ListItem>ตรวจสอบยอดที่โต๊ะพร้อมเก็บเงินได้เลย</ListItem>
										<ListItem>มีปุ่มสั่งพิมพ์ใบเสร็จรับเงิน</ListItem>
										<ListItem>
											กดเคลียร์ออเดอร์หลังจากลูกค้าจ่ายเงินแล้ว
										</ListItem>
									</UnorderedList>
								</chakra.p>
							</Box>
							<Box w="full" h="full">
								<Image src={res_system_4} boxSize="100%" objectFit="cover" />
							</Box>
						</SimpleGrid>
					</Box>
					<SimpleGrid
						display={"none"}
						columns={{ base: 1, md: 2, xl: 4 }}
						spacing={{ base: 5, md: 8, xl: 10 }}
						pb={{ base: 10, md: 16 }}
						bg="white"
						_dark={{ bg: "gray.800" }}
					>
						<Feature2 title="ระบบหลังบ้าน" img={res_system_1}>
							<UnorderedList>
								<ListItem>รายการสินค้าพร้อมแยกหมวดหมู่</ListItem>
								<ListItem>ปรับแต่งชื่อโต๊ะ ชื่อร้านและโลโก้</ListItem>
								<ListItem>รายละเอียดการเข้าใช้งานของพนักงาน</ListItem>
								<ListItem>กำหนด LINE Notify Token</ListItem>
								<ListItem>สถิติข้อมูลการขาย</ListItem>
							</UnorderedList>
						</Feature2>
						<Feature2 title="ระบบจดออเดอร์" img={res_system_2}>
							<UnorderedList>
								<ListItem>รายการโต๊ะพร้อมโค๊ตกำกับ</ListItem>
								<ListItem>เลือกสินค้าแทนการจดออเดอร์กระดาษ</ListItem>
								<ListItem>ดูรายการซื้อทั้งหมดของลูกค้าแต่ละโต๊ะ</ListItem>
								<ListItem>
									เคลียร์รายการเดิมพร้อมเปลี่ยนโค๊ตสำหรับลูกค้าท่านใหม่
								</ListItem>
							</UnorderedList>
						</Feature2>
						<Feature2 title="ระบบ QR Menu" img={res_system_3}>
							<UnorderedList>
								<ListItem>ลูกค้าสามารถสั่งสินค้าได้ด้วยตนเอง</ListItem>
								<ListItem>มีเวลาเลือกสินค้าได้อย่างอิสระ</ListItem>
								<ListItem>จะสั่งซื้อเพิ่มมื่อไหร่ก็ได้</ListItem>
								<ListItem>แสดงรายการสั่งซื้อ</ListItem>
								<ListItem>สรุปยอดค่าใช้จ่ายรวม</ListItem>
							</UnorderedList>
						</Feature2>
						<Feature2 title="แจ้งเตือนรายการที่แอพ LINE" img={res_system_4}>
							<UnorderedList>
								<ListItem>แจ้งรายการสั่งซื้อมาที่แอพ LINE</ListItem>
								<ListItem>แจ้งเตือนมาแค่เจ้าของ LINE</ListItem>
								<ListItem>แจ้งเตือนเข้ากลุ่ม LINE</ListItem>
								<ListItem>รับรู้รายการสั่งซื้อพร้อมกัน</ListItem>
							</UnorderedList>
						</Feature2>
					</SimpleGrid>
					<SimpleGrid
						display={"none"}
						columns={{
							base: 1,
							sm: 2,
							md: 3,
							lg: 4,
						}}
						spacingX={{
							base: 16,
							lg: 24,
						}}
						spacingY={20}
						mt={6}
					>
						<Feature
							color="red"
							title="เพิ่มสินค้าพร้อมแยกหมวดหมู่"
							icon={
								<path
									fillRule="evenodd"
									d="M14.243 5.757a6 6 0 10-.986 9.284 1 1 0 111.087 1.678A8 8 0 1118 10a3 3 0 01-4.8 2.401A4 4 0 1114 10a1 1 0 102 0c0-1.537-.586-3.07-1.757-4.243zM12 10a2 2 0 10-4 0 2 2 0 004 0z"
									clipRule="evenodd"
								/>
							}
						>
							Hand crafted dashboards for everything from Recurring Revenue to
							Customer Churn.
						</Feature>

						<Feature
							color="pink"
							title="ปรับแต่งชื่อโต๊ะ ชื่อร้านและโลโก้"
							icon={
								<path
									fillRule="evenodd"
									d="M7 2a1 1 0 00-.707 1.707L7 4.414v3.758a1 1 0 01-.293.707l-4 4C.817 14.769 2.156 18 4.828 18h10.343c2.673 0 4.012-3.231 2.122-5.121l-4-4A1 1 0 0113 8.172V4.414l.707-.707A1 1 0 0013 2H7zm2 6.172V4h2v4.172a3 3 0 00.879 2.12l1.027 1.028a4 4 0 00-2.171.102l-.47.156a4 4 0 01-2.53 0l-.563-.187a1.993 1.993 0 00-.114-.035l1.063-1.063A3 3 0 009 8.172z"
									clipRule="evenodd"
								/>
							}
						>
							Your central hub that helps you see, and react to, absolutely
							everything that’s happening.
						</Feature>

						<Feature
							color="green"
							title="กำหนด LINE Notify Token สำหรับการแจ้งเตือน"
							icon={
								<>
									<path
										fillRule="evenodd"
										d="M6.625 2.655A9 9 0 0119 11a1 1 0 11-2 0 7 7 0 00-9.625-6.492 1 1 0 11-.75-1.853zM4.662 4.959A1 1 0 014.75 6.37 6.97 6.97 0 003 11a1 1 0 11-2 0 8.97 8.97 0 012.25-5.953 1 1 0 011.412-.088z"
										clipRule="evenodd"
									/>
									<path
										fillRule="evenodd"
										d="M5 11a5 5 0 1110 0 1 1 0 11-2 0 3 3 0 10-6 0c0 1.677-.345 3.276-.968 4.729a1 1 0 11-1.838-.789A9.964 9.964 0 005 11zm8.921 2.012a1 1 0 01.831 1.145 19.86 19.86 0 01-.545 2.436 1 1 0 11-1.92-.558c.207-.713.371-1.445.49-2.192a1 1 0 011.144-.83z"
										clipRule="evenodd"
									/>
									<path
										fillRule="evenodd"
										d="M10 10a1 1 0 011 1c0 2.236-.46 4.368-1.29 6.304a1 1 0 01-1.838-.789A13.952 13.952 0 009 11a1 1 0 011-1z"
										clipRule="evenodd"
									/>
								</>
							}
						>
							Our forecasting is your magical crystal ball that helps you
							predict and plan for the future.
						</Feature>
						<Feature
							color="purple"
							title="สถิติข้อมูลการขาย"
							icon={
								<path
									fillRule="evenodd"
									d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
									clipRule="evenodd"
								/>
							}
						>
							How does your company compare? Learn how your company stacks up in
							the industry.
						</Feature>

						<Feature
							color="yellow"
							title="รายละเอียดการเข้าใช้งานของพนักงาน"
							icon={
								<path
									fillRule="evenodd"
									d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z"
									clipRule="evenodd"
								/>
							}
						>
							Stay informed with daily, weekly, or monthly reports on all your
							insights data.
						</Feature>

						<Feature
							color="blue"
							title="พนักงานจดออเดอร์บนมือถือ"
							icon={
								<path
									fillRule="evenodd"
									d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
									clipRule="evenodd"
								/>
							}
						>
							Organize your customers to bring meaningful and comparative
							insights across your dashboards.
						</Feature>
						<Feature
							color="brand"
							title="ดูรายการซื้อทั้งหมดของลูกค้าแต่ละโต๊ะ"
							icon={
								<path
									fillRule="evenodd"
									d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z"
									clipRule="evenodd"
								/>
							}
						>
							Improve your conversion rates by monitoring exactly what’s going
							on while your customers are in trial.
						</Feature>

						<Feature
							color="purple"
							title="จัดการเปลี่ยนโค๊ตหลังจากเช็คบิลแล้ว"
							icon={
								<>
									<path
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
										fill="currentColor"
										aria-hidden="true"
									>
										<path d="M10 3.5a1.5 1.5 0 013 0V4a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-.5a1.5 1.5 0 000 3h.5a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-.5a1.5 1.5 0 00-3 0v.5a1 1 0 01-1 1H6a1 1 0 01-1-1v-3a1 1 0 00-1-1h-.5a1.5 1.5 0 010-3H4a1 1 0 001-1V6a1 1 0 011-1h3a1 1 0 001-1v-.5z" />
									</path>
								</>
							}
						>
							Personalized segmentation, rich customer profiles, manual
							subscriptions, and more!
						</Feature>

						<Feature
							color="pink"
							title="Sparkles"
							icon={
								<path
									fillRule="evenodd"
									d="M5 2a1 1 0 011 1v1h1a1 1 0 010 2H6v1a1 1 0 01-2 0V6H3a1 1 0 010-2h1V3a1 1 0 011-1zm0 10a1 1 0 011 1v1h1a1 1 0 110 2H6v1a1 1 0 11-2 0v-1H3a1 1 0 110-2h1v-1a1 1 0 011-1zM12 2a1 1 0 01.967.744L14.146 7.2 17.5 9.134a1 1 0 010 1.732l-3.354 1.935-1.18 4.455a1 1 0 01-1.933 0L9.854 12.8 6.5 10.866a1 1 0 010-1.732l3.354-1.935 1.18-4.455A1 1 0 0112 2z"
									clipRule="evenodd"
								/>
							}
						>
							Merge external data with your metrics for deeper insights in to
							your customers and your business.
						</Feature>

						<Feature
							color="red"
							title="Stars"
							icon={
								<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
							}
						>
							Want your metrics in other services? Extend and integrate to our
							every part of your business.
						</Feature>

						<Feature
							color="green"
							title="Support"
							icon={
								<path
									fillRule="evenodd"
									d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-2 0c0 .993-.241 1.929-.668 2.754l-1.524-1.525a3.997 3.997 0 00.078-2.183l1.562-1.562C15.802 8.249 16 9.1 16 10zm-5.165 3.913l1.58 1.58A5.98 5.98 0 0110 16a5.976 5.976 0 01-2.516-.552l1.562-1.562a4.006 4.006 0 001.789.027zm-4.677-2.796a4.002 4.002 0 01-.041-2.08l-.08.08-1.53-1.533A5.98 5.98 0 004 10c0 .954.223 1.856.619 2.657l1.54-1.54zm1.088-6.45A5.974 5.974 0 0110 4c.954 0 1.856.223 2.657.619l-1.54 1.54a4.002 4.002 0 00-2.346.033L7.246 4.668zM12 10a2 2 0 11-4 0 2 2 0 014 0z"
									clipRule="evenodd"
								/>
							}
						>
							Send important updates from here to your team’s Support channels.
						</Feature>

						<Feature
							color="blue"
							title="Finger Printing for Robots"
							icon={
								<>
									<path
										fillRule="evenodd"
										d="M3 4a1 1 0 011-1h3a1 1 0 011 1v3a1 1 0 01-1 1H4a1 1 0 01-1-1V4zm2 2V5h1v1H5zM3 13a1 1 0 011-1h3a1 1 0 011 1v3a1 1 0 01-1 1H4a1 1 0 01-1-1v-3zm2 2v-1h1v1H5zM13 3a1 1 0 00-1 1v3a1 1 0 001 1h3a1 1 0 001-1V4a1 1 0 00-1-1h-3zm1 2v1h1V5h-1z"
										clipRule="evenodd"
									/>
									<path d="M11 4a1 1 0 10-2 0v1a1 1 0 002 0V4zM10 7a1 1 0 011 1v1h2a1 1 0 110 2h-3a1 1 0 01-1-1V8a1 1 0 011-1zM16 9a1 1 0 100 2 1 1 0 000-2zM9 13a1 1 0 011-1h1a1 1 0 110 2v2a1 1 0 11-2 0v-3zM7 11a1 1 0 100-2H4a1 1 0 100 2h3zM17 13a1 1 0 01-1 1h-2a1 1 0 110-2h2a1 1 0 011 1zM16 17a1 1 0 100-2h-3a1 1 0 100 2h3z" />
								</>
							}
						>
							Goals, Breakouts, Instant notifications, Comparisons, Annotations
							and Cohort Analysis!
						</Feature>
					</SimpleGrid>
				</Box>
			</Flex>
			<Box textAlign={"center"} mt={4}>
				<Link px={10} fontWeight={"bold"} onClick={() => navigate("/pmanual")}>
					ฟังก์ชั่นทั้งหมด
				</Link>
			</Box>
		</Container>
	);
};

const NotUser = () => {
	const Feature = (props) => {
		return (
			<Flex>
				<Flex shrink={0}>
					<Flex
						alignItems="center"
						justifyContent="center"
						h={12}
						w={12}
						rounded="md"
						_light={{
							bg: "brand.500",
						}}
						color="white"
						bgColor="cyan.700"
					>
						<Icon
							boxSize={6}
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							aria-hidden="true"
						>
							{props.icon}
						</Icon>
					</Flex>
				</Flex>
				<Box ml={4}>
					<chakra.dt
						fontSize="lg"
						fontWeight="medium"
						lineHeight="6"
						_light={{
							color: "gray.900",
						}}
					>
						{props.title}
					</chakra.dt>
					<chakra.dd
						mt={2}
						color="gray.500"
						_dark={{
							color: "gray.400",
						}}
					>
						{props.children}
					</chakra.dd>
				</Box>
			</Flex>
		);
	};

	return (
		<Container maxW="container.xl" mt={5} display={"none"}>
			<Flex w="auto" justifyContent="center" alignItems="center">
				<Box
					py={20}
					bg="white"
					_dark={{
						bg: "gray.800",
					}}
					rounded="xl"
				>
					<Box maxW="7xl" mx="auto">
						<Box
							textAlign={{
								lg: "center",
							}}
						>
							<chakra.p
								mt={2}
								fontSize={{
									base: "3xl",
									sm: "4xl",
								}}
								lineHeight="8"
								fontWeight="extrabold"
								letterSpacing="tight"
								_light={{
									color: "gray.900",
								}}
							>
								ระบบ QR Menu นี้เหมาะกับใคร
							</chakra.p>
							<chakra.p
								mt={4}
								maxW="2xl"
								fontSize="xl"
								mx={{
									lg: "auto",
								}}
								color="gray.500"
								_dark={{
									color: "gray.400",
								}}
							>
								ตรวจสอบความต้องการเพื่อให้ตอบโจทย์สำหรับธุรกิจคุณ
							</chakra.p>
						</Box>

						<Box mt={10}>
							<Stack
								spacing={{
									base: 10,
									md: 0,
								}}
								display={{
									md: "grid",
								}}
								gridTemplateColumns={{
									md: "repeat(2,1fr)",
								}}
								gridColumnGap={{
									md: 8,
								}}
								gridRowGap={{
									md: 10,
								}}
							>
								<Feature
									title="Competitive exchange rates"
									icon={
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
										/>
									}
								>
									Lorem ipsum, dolor sit amet consectetur adipisicing elit.
									Maiores impedit perferendis suscipit eaque, iste dolor
									cupiditate blanditiis ratione.
								</Feature>

								<Feature
									title=" No hidden fees"
									icon={
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
										/>
									}
								>
									Lorem ipsum, dolor sit amet consectetur adipisicing elit.
									Maiores impedit perferendis suscipit eaque, iste dolor
									cupiditate blanditiis ratione.
								</Feature>

								<Feature
									title="Transfers are instant"
									icon={
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M13 10V3L4 14h7v7l9-11h-7z"
										/>
									}
								>
									Lorem ipsum, dolor sit amet consectetur adipisicing elit.
									Maiores impedit perferendis suscipit eaque, iste dolor
									cupiditate blanditiis ratione.
								</Feature>

								<Feature
									title="Mobile notifications"
									icon={
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
										/>
									}
								>
									Lorem ipsum, dolor sit amet consectetur adipisicing elit.
									Maiores impedit perferendis suscipit eaque, iste dolor
									cupiditate blanditiis ratione.
								</Feature>
							</Stack>
						</Box>
					</Box>
				</Box>
			</Flex>
		</Container>
	);
};

export default Home;
