import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../config/apiConfig";
import axios from "axios";
import { Box, Spinner, Image } from "@chakra-ui/react";
import MenuList from "./MenuList";
import bgImage from "../images/bg_monitor.jpg";

const CustomerNoCode = () => {
	const searchParams = new URLSearchParams(window.location.search);
	const shopcode = searchParams.get("shop");
	const unitcode = searchParams.get("unit");

	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [displayContent, setDisplayContent] = useState(null);
	const [checkLastDate, setCheckLastDate] = useState();
	const [shopLogo, setShopLogo] = useState();
	const [errorMessage, setErrorMessage] = useState("QR Code is incorrect");

	useEffect(() => {
		const fetchShopData = async () => {
			try {
				const response = await axios.post(
					`${API_BASE_URL}/api/shopping/shop_mobile_home`,
					{ shopIdentifire: shopcode }
				);
				if (response.data.results.length === 0) {
					setIsLoading(false);
					setError(true);
				} else if (response.data.results[0].last_day === 1) {
					setIsLoading(false);
					setCheckLastDate(true);
				} else if (response.data.results[0].last_day === 0) {
					setShopLogo(
						response.data.results[0].logo !== ""
							? `${API_BASE_URL}/image/logo/${response.data.results[0].logo}`
							: `${API_BASE_URL}/image/logo/default.png`
					);
					fetchCheckCode();
				}
			} catch (error) {
				console.error("Error fetching shop data:", error);
				setError(true);
			}
		};

		const fetchCheckCode = async () => {
			try {
				const response = await axios.post(
					`${API_BASE_URL}/api/shopping/check_code`,
					{ shopIdentifire: shopcode, unit: unitcode }
				);
				setTimeout(() => {
					// console.log(response.data.unitShopResult);
					if (response.data.message === "success") {
						if (response.data.unitShopResult.is_delete === "1") {
							setIsLoading(false);
							setError(true);
							setErrorMessage("QR Code has been deactivated");
						} else {
							setIsLoading(false);
							setDisplayContent(<MenuList data={response.data} />);
							fetchLimitTime();
						}
					} else {
						setIsLoading(false);
						setError(true);
					}
				}, 3000);
			} catch (err) {
				console.error("Error checking code:", err);
				setIsLoading(false);
				setError(true);
			}
		};

		const fetchLimitTime = () => {
			window.history.replaceState({}, "", "/menu");
			const timer = setTimeout(() => {
				window.location.reload();
			}, 600000);
			return () => clearTimeout(timer);
		};

		// console.log("ss", shopcode);
		if (shopcode) {
			fetchShopData();
		}
		if (isLoading && !shopcode && !unitcode) {
			navigate("/rescan", { replace: true });
		}
	}, [isLoading, shopcode, unitcode, navigate]);

	if (isLoading) {
		return (
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				height="100vh"
				bgImage={bgImage}
			>
				<Box minH={"100px"}>
					{shopLogo && <Image src={shopLogo} w={"100px"} />}
				</Box>
				<Spinner size="sm" mt={4} />
			</Box>
		);
	}

	if (checkLastDate) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				height="100vh"
				bgImage={bgImage}
				fontWeight={"600"}
				color={"red"}
			>
				Service has expired
			</Box>
		);
	}

	if (error) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				height="100vh"
				bgImage={bgImage}
				fontWeight={"600"}
				color={"red"}
			>
				{errorMessage}
			</Box>
		);
	}

	return <Box>{displayContent}</Box>;
};

export default CustomerNoCode;
