import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
	Flex,
	Container,
	Box,
	Text,
	useColorModeValue,
	VStack,
	List,
	ListItem,
	ListIcon,
	HStack,
	Heading,
	Button,
	Stack,
} from "@chakra-ui/react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { IoRestaurant } from "react-icons/io5";
import { MdHotel } from "react-icons/md";

function CardPrice({ businessType, price, priceCount }) {
	const { t } = useTranslation();
	const bgColor = useColorModeValue("gray.50", "gray.600");
	const [activeButton, setActiveButton] = useState(
		businessType === "restaurant" ? 0 : 1
	);
	const handleRestaurantClick = () => {
		setActiveButton(0);
	};
	const handleAccommodationClick = () => {
		setActiveButton(1);
	};

	const choice = [
		{
			price: [90, 150, 210],
			unit_name: "โต๊ะ",
		},
		{
			price: [60, 90, 120],
			unit_name: "ห้อง",
		},
	];

	return (
		<>
			<Flex justifyContent="center" alignItems="center">
				<Box
					bgColor={"cyan.700"}
					w={{ base: "3xl", md: "4xl" }}
					borderTopRadius={"xl"}
				>
					<HStack justifyContent="center">
						<Flex pt={3} gap={2} bgClip="text" color={"cyan.100"}>
							<Text fontSize={{ base: "md", md: "xl" }} fontWeight="400">
								ต้นทุนระบบเฉลี่ยเพียงวันละ
							</Text>
							<Text
								fontSize={{ base: "2xl", md: "3xl" }}
								fontWeight="800"
								mt={{ base: "-8px", md: "-10px" }}
							>
								{price && (price / 365).toFixed(2)}
							</Text>
							<Text fontSize={{ base: "md", md: "xl" }} fontWeight="400">
								บาทเท่านั้น
							</Text>
						</Flex>
					</HStack>
					<Text
						size={"xs"}
						fontWeight={"300"}
						textAlign={"center"}
						color={"gray.500"}
						display={"none"}
					>
						จำกัด {priceCount} ร้านสุดท้าย
					</Text>
				</Box>
			</Flex>
			<Box py={20} display={"none"}>
				<Stack spacing={0} textAlign={"center"}>
					<Heading>{t("page_pricing_header")}</Heading>
					<Text>เพิ่มยอดขายและความสะดวกสบายให้ธุรกิจของคุณ</Text>
				</Stack>

				<Container
					maxW="container.xl"
					textAlign="center"
					justify="center"
					pb={5}
					pt={{ base: 3, md: 5 }}
				>
					<Box
						display="none"
						alignItems="center"
						justifyContent="center"
						my={2}
					>
						<Stack direction="row" spacing={4}>
							<Button
								onClick={handleRestaurantClick}
								color={activeButton ? "cyan.700" : "gray.100"}
								bgColor={!activeButton ? "cyan.700" : "gray.100"}
								_hover={"none"}
								leftIcon={<IoRestaurant />}
								w={36}
							>
								ร้านอาหาร
							</Button>
							<Button
								onClick={handleAccommodationClick}
								color={!activeButton ? "cyan.700" : "gray.100"}
								bgColor={activeButton ? "cyan.700" : "gray.100"}
								_hover={"none"}
								leftIcon={<MdHotel />}
								w={36}
							>
								ที่พัก
							</Button>
						</Stack>
					</Box>
					<Flex
						overflowX="auto"
						justify={{ base: "auto", md: "auto", lg: "center" }}
						gridGap={3}
						py={3}
						px={{ base: 0, lg: 3 }}
						pb={4}
					>
						<Flex gap={{ base: 8, md: 10, xl: 12 }} px={5}>
							<Box
								pt={3}
								borderRadius={"2xl"}
								border={"1px solid #e7e7e7"}
								w={{ base: "15em", md: "18em" }}
							>
								<Box py={{ base: 2, md: 4 }} px={{ base: 3, md: 5 }}>
									<Text
										fontWeight="600"
										fontSize="2xl"
										mb={{ base: 0, md: 2 }}
										color={"cyan.700"}
									>
										ขนาดเริ่มต้น
									</Text>
									<HStack justifyContent="center">
										<Text fontSize="3xl" fontWeight="600">
											฿
										</Text>
										<Text
											fontSize={{ base: "4xl", md: "5xl" }}
											fontWeight="900"
										>
											{choice[activeButton].price[0]}
										</Text>
										<Text fontSize="xl" color="gray.500">
											/30 วัน
										</Text>
									</HStack>
									<Text size={"xs"}>
										เฉลี่ยวันละ {choice[activeButton].price[0] / 30} บาท
									</Text>
								</Box>
								<VStack
									bg={bgColor}
									py={{ base: 5, md: 7 }}
									borderBottomRadius={"2xl"}
								>
									<List spacing={{ base: 1, md: 3 }} textAlign="start" px={2}>
										<ListItem>
											<ListIcon
												as={FaCheckCircle}
												color="green.500"
												fontSize={"1.4em"}
											/>
											จำนวน 1 - 15 {choice[activeButton].unit_name}
										</ListItem>
										<ListItem>
											<ListIcon
												as={FaCheckCircle}
												color="green.500"
												fontSize={"1.4em"}
											/>
											แอดมินจัดการร้าน
										</ListItem>
										<ListItem>
											<ListIcon
												as={FaCheckCircle}
												color="green.500"
												fontSize={"1.4em"}
											/>
											ใช้งาน QR Menu
										</ListItem>
										<ListItem>
											<ListIcon
												as={FaCheckCircle}
												color="green.500"
												fontSize={"1.4em"}
											/>
											แจ้งรายการผ่าน LINE
										</ListItem>
										<ListItem>
											{!activeButton ? (
												<ListIcon
													as={FaCheckCircle}
													color="green.500"
													fontSize={"1.4em"}
												/>
											) : (
												<ListIcon
													as={FaTimesCircle}
													color="red.500"
													fontSize={"1.4em"}
												/>
											)}
											จดออเดอร์บนมือถือ
										</ListItem>
									</List>
								</VStack>
							</Box>
							<Box
								pt={3}
								borderRadius={"2xl"}
								border={"1px solid #e7e7e7"}
								w={{ base: "15em", md: "18em" }}
							>
								<Box py={{ base: 2, md: 4 }} px={{ base: 3, md: 5 }}>
									<Text
										fontWeight="600"
										fontSize="2xl"
										mb={{ base: 0, md: 2 }}
										color={"cyan.700"}
									>
										ขนาดกลาง
									</Text>
									<HStack justifyContent="center">
										<Text fontSize="3xl" fontWeight="600">
											฿
										</Text>
										<Text
											fontSize={{ base: "4xl", md: "5xl" }}
											fontWeight="900"
										>
											{choice[activeButton].price[1]}
										</Text>
										<Text fontSize="xl" color="gray.500">
											/30 วัน
										</Text>
									</HStack>
									<Text size={"xs"}>
										เฉลี่ยวันละ {choice[activeButton].price[1] / 30} บาท
									</Text>
								</Box>
								<VStack
									bg={bgColor}
									py={{ base: 5, md: 7 }}
									borderBottomRadius={"2xl"}
								>
									<List spacing={{ base: 1, md: 3 }} textAlign="start" px={2}>
										<ListItem>
											<ListIcon
												as={FaCheckCircle}
												color="green.500"
												fontSize={"1.4em"}
											/>
											จำนวน 16 - 30 {choice[activeButton].unit_name}
										</ListItem>
										<ListItem>
											<ListIcon
												as={FaCheckCircle}
												color="green.500"
												fontSize={"1.4em"}
											/>
											แอดมินจัดการร้าน
										</ListItem>
										<ListItem>
											<ListIcon
												as={FaCheckCircle}
												color="green.500"
												fontSize={"1.4em"}
											/>
											ใช้งาน QR Menu
										</ListItem>
										<ListItem>
											<ListIcon
												as={FaCheckCircle}
												color="green.500"
												fontSize={"1.4em"}
											/>
											แจ้งรายการผ่าน LINE
										</ListItem>
										<ListItem>
											{!activeButton ? (
												<ListIcon
													as={FaCheckCircle}
													color="green.500"
													fontSize={"1.4em"}
												/>
											) : (
												<ListIcon
													as={FaTimesCircle}
													color="red.500"
													fontSize={"1.4em"}
												/>
											)}
											จดออเดอร์บนมือถือ
										</ListItem>
									</List>
								</VStack>
							</Box>
							<Box
								pt={3}
								borderRadius={"2xl"}
								border={"1px solid #e7e7e7"}
								w={{ base: "15em", md: "18em" }}
							>
								<Box py={{ base: 2, md: 4 }} px={{ base: 3, md: 5 }}>
									<Text
										fontWeight="600"
										fontSize="2xl"
										mb={{ base: 0, md: 2 }}
										color={"cyan.700"}
									>
										รองรับการเติบโต
									</Text>
									<HStack justifyContent="center">
										<Text fontSize="3xl" fontWeight="600">
											฿
										</Text>
										<Text
											fontSize={{ base: "4xl", md: "5xl" }}
											fontWeight="900"
										>
											{choice[activeButton].price[2]}
										</Text>
										<Text fontSize="xl" color="gray.500">
											/30 วัน
										</Text>
									</HStack>
									<Text size={"xs"}>
										เฉลี่ยวันละ {choice[activeButton].price[2] / 30} บาท
									</Text>
								</Box>
								<VStack
									bg={bgColor}
									py={{ base: 5, md: 7 }}
									borderBottomRadius={"2xl"}
								>
									<List spacing={{ base: 1, md: 3 }} textAlign="start" px={2}>
										<ListItem>
											<ListIcon
												as={FaCheckCircle}
												color="green.500"
												fontSize={"1.4em"}
											/>
											จำนวน 31 - 50 {choice[activeButton].unit_name}
										</ListItem>
										<ListItem>
											<ListIcon
												as={FaCheckCircle}
												color="green.500"
												fontSize={"1.4em"}
											/>
											แอดมินจัดการร้าน
										</ListItem>
										<ListItem>
											<ListIcon
												as={FaCheckCircle}
												color="green.500"
												fontSize={"1.4em"}
											/>
											ใช้งาน QR Menu
										</ListItem>
										<ListItem>
											<ListIcon
												as={FaCheckCircle}
												color="green.500"
												fontSize={"1.4em"}
											/>
											แจ้งรายการผ่าน LINE
										</ListItem>
										<ListItem>
											{!activeButton ? (
												<ListIcon
													as={FaCheckCircle}
													color="green.500"
													fontSize={"1.4em"}
												/>
											) : (
												<ListIcon
													as={FaTimesCircle}
													color="red.500"
													fontSize={"1.4em"}
												/>
											)}
											จดออเดอร์บนมือถือ
										</ListItem>
									</List>
								</VStack>
							</Box>
						</Flex>
					</Flex>
				</Container>
			</Box>
		</>
	);
}

export default CardPrice;
