import React, { useState, useEffect, useContext, useMemo } from "react";
import axios from "axios";
import {
	useLocation,
	Outlet,
	Link as ReactRouterLink,
	useNavigate,
} from "react-router-dom";
import {
	Text,
	IconButton,
	Box,
	CloseButton,
	Flex,
	Icon,
	useColorModeValue,
	Drawer,
	DrawerContent,
	useDisclosure,
	HStack,
	Image,
	useColorMode,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	VStack,
} from "@chakra-ui/react";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import { FiMenu } from "react-icons/fi";
import { PiShoppingCart, PiBellRingingBold } from "react-icons/pi";
import { LuBookMarked } from "react-icons/lu";
import { BsShop, BsPeople } from "react-icons/bs";
import { AiOutlineBarChart, AiOutlineDeploymentUnit } from "react-icons/ai";
import { MdOutlineAutorenew } from "react-icons/md";
import { TbCategoryPlus } from "react-icons/tb";
import { useAuth } from "../hooks/AuthProvider";
import { API_BASE_URL } from "../config/apiConfig";
import ShopContext from "../hooks/ShopContext";
import logo from "../images/logo250.png";
import ManageOrder from "./content/ManageOrder";
import ProfilePopover from "./component/ProfilePopover";

export default function Sidebar() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [selectedNavItem, setSelectedNavItem] = useState();
	const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(false);

	const { shopValueId } = useContext(ShopContext);

	useEffect(() => {
		if (shopValueId) {
			const localStorageKey = "sevdata";
			const encodedShopValueId = `sevdata_${btoa(shopValueId)}`;

			let oldData = JSON.parse(localStorage.getItem(localStorageKey));
			if (!Array.isArray(oldData)) {
				oldData = [];
			}
			if (!oldData.includes(encodedShopValueId)) {
				setIsWelcomeModalOpen(true);
				const newData = [...oldData, encodedShopValueId];
				localStorage.setItem(localStorageKey, JSON.stringify(newData));
			}
		}
	}, [shopValueId]);

	return (
		<Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
			<SidebarContent
				onClose={onClose}
				selectedNavItem={selectedNavItem}
				handleNavItemClick={setSelectedNavItem}
				display={{ base: "none", md: "block" }}
			/>
			<Drawer
				isOpen={isOpen}
				placement="left"
				onClose={onClose}
				returnFocusOnClose={false}
				onOverlayClick={onClose}
			>
				<DrawerContent>
					<SidebarContent
						onClose={onClose}
						handleNavItemClick={setSelectedNavItem}
					/>
				</DrawerContent>
			</Drawer>
			{/* mobilenav */}
			<MobileNav
				onOpen={onOpen}
				handleNavItemClick={setSelectedNavItem} // Pass handleNavItemClick to MobileNav
			/>
			<Box
				ml={{ base: 0, md: 60 }}
				p={{ base: 4, md: 8 }}
				pt={{ base: "6em", md: "7em" }}
			>
				<Outlet />
			</Box>
			<WelcomeModal
				isOpen={isWelcomeModalOpen}
				onClose={() => setIsWelcomeModalOpen(false)}
			/>
		</Box>
	);
}

const SidebarContent = ({
	onClose,
	selectedNavItem,
	handleNavItemClick,
	...rest
}) => {
	const LinkItemsHotel = [
		{
			name: "แดชบอร์ด",
			code: "dashboard",
			path: "/dashboard",
			icon: AiOutlineBarChart,
		},
		{
			name: "หมวดหมู่",
			code: "category",
			path: "/category",
			icon: TbCategoryPlus,
		},
		{ name: "สินค้า", code: "product", path: "/product", icon: PiShoppingCart },
		{
			name: "รายชื่อห้อง",
			code: "unit",
			path: "/unit",
			icon: AiOutlineDeploymentUnit,
		},
		{
			name: "แจ้งเตือน",
			code: "notification",
			path: "/notification",
			icon: PiBellRingingBold,
		},
		{ name: "รายละเอียดที่พัก", code: "shop", path: "/shop", icon: BsShop },
		{ name: "พนักงาน", code: "employee", path: "/employee", icon: BsPeople },
		{
			name: "ต่ออายุ",
			code: "renew",
			path: "/renew",
			icon: MdOutlineAutorenew,
		},
		{ name: "คู่มือ", code: "manual", path: "/manual", icon: LuBookMarked },
	];

	const LinkItemsPublic = [
		{
			name: "แดชบอร์ด",
			code: "dashboard",
			path: "/dashboard",
			icon: AiOutlineBarChart,
		},
		{
			name: "หมวดหมู่",
			code: "category",
			path: "/category",
			icon: TbCategoryPlus,
		},
		{ name: "สินค้า", code: "product", path: "/product", icon: PiShoppingCart },
		{
			name: "รายชื่อโต๊ะ",
			code: "unit",
			path: "/unit",
			icon: AiOutlineDeploymentUnit,
		},
		{
			name: "แจ้งเตือน",
			code: "notification",
			path: "/notification",
			icon: PiBellRingingBold,
		},
		{ name: "รายละเอียดร้าน", code: "shop", path: "/shop", icon: BsShop },
		{ name: "พนักงาน", code: "employee", path: "/employee", icon: BsPeople },
		{
			name: "ต่ออายุ",
			code: "renew",
			path: "/renew",
			icon: MdOutlineAutorenew,
		},
		{ name: "คู่มือ", code: "manual", path: "/manual", icon: LuBookMarked },
		// { name: "อัพเดท", code: "update", path: "/update", icon: MdBrowserUpdated },
	];

	const auth = useAuth();

	const LinkItems =
		auth.user.business_type === "hotel" ? LinkItemsHotel : LinkItemsPublic;

	const location = useLocation();
	const currentPath = location.pathname;
	useEffect(() => {
		const selectedItem = LinkItems.find((item) => item.path === currentPath);
		if (selectedItem) {
			handleNavItemClick(selectedItem.code);
		}
	}, [currentPath, handleNavItemClick, LinkItems]);

	const { colorMode, toggleColorMode } = useColorMode();
	const navigate = useNavigate();
	const handleHome = () => {
		navigate("/");
	};

	return (
		<Box
			transition="3s ease"
			bg={useColorModeValue("white", "gray.900")}
			borderRight="1px"
			borderRightColor={useColorModeValue("gray.200", "gray.700")}
			w={{ base: "full", md: 60 }}
			pos="fixed"
			h="full"
			pt={3}
			{...rest}
		>
			<Flex
				p={5}
				pt={8}
				pb={10}
				h="5"
				alignItems="center"
				mx="2"
				justifyContent="space-between"
			>
				<Box onClick={handleHome} cursor={"pointer"}>
					<Image src={logo} h={10} />
				</Box>
				<CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
			</Flex>
			<Box overflowY="auto" maxH={{ base: "calc(100vh - 105px)" }}>
				{LinkItems.map(({ name, icon, code, path }) => (
					<NavItem
						key={name}
						as={ReactRouterLink}
						icon={icon}
						to={path}
						selected={selectedNavItem === code}
						onClick={() => {
							handleNavItemClick(code);
							onClose();
						}}
						mb={3}
						pl={3}
						py={4}
					>
						<Text>{name}</Text>
					</NavItem>
				))}
				<Flex justifyContent="center" alignItems="center" gap={5} mt={5}>
					<Icon
						as={colorMode === "light" ? MoonIcon : SunIcon}
						boxSize={4}
						onClick={toggleColorMode}
						cursor={"pointer"}
					/>
				</Flex>
			</Box>
		</Box>
	);
};

const NavItem = ({ icon, children, selected, onClick, ...rest }) => {
	return (
		<Box
			as="a"
			href="#"
			style={{ textDecoration: "none" }}
			_focus={{ boxShadow: "none" }}
		>
			<Flex
				align="center"
				p="4"
				mx="4"
				borderRadius="lg"
				role="group"
				cursor="pointer"
				bg={selected ? "cyan.800" : ""}
				color={selected ? "white" : ""}
				{...rest}
				onClick={onClick}
			>
				{icon && <Icon mr="4" fontSize="16" as={icon} />}
				{children}
			</Flex>
		</Box>
	);
};

const MobileNav = ({ onOpen, ...rest }) => {
	const auth = useAuth();
	const headers = useMemo(
		() => ({
			Authorization: auth.user.token,
		}),
		[auth.user.token]
	);
	const { setShopId } = useContext(ShopContext);
	const [selectedShopId, setSelectedShopId] = useState("");

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.post(
					`${API_BASE_URL}/api/site/list_shop`,
					{},
					{ headers }
				);
				// console.log(response.data);
				if (response.data.results.length > 0) {
					if (!selectedShopId) {
						setSelectedShopId(response.data.results[0].id);
					}
				}
			} catch (error) {
				console.error("Error list_shop:", error);
			}
		};

		// console.log(listShop);
		if (headers.Authorization !== undefined) {
			fetchData();
		}
	}, [headers, selectedShopId]); // Remove selectedShopId from the dependency array of useEffect

	useEffect(() => {
		setShopId(selectedShopId);
	}, [selectedShopId, setShopId]);

	return (
		<>
			<Flex
				ml={{ base: 0, md: 60 }}
				px={{ base: 4, md: 8 }}
				height="20"
				alignItems="center"
				bg={useColorModeValue("white", "gray.900")}
				borderBottomWidth="1px"
				borderBottomColor={useColorModeValue("gray.200", "gray.700")}
				justifyContent={{ base: "space-between", md: "flex" }}
				pos={"fixed"}
				top={0}
				w={"-webkit-fill-available"}
				zIndex={9}
				{...rest}
			>
				<IconButton
					display={{ base: "flex", md: "none" }}
					onClick={onOpen}
					variant="outline"
					aria-label="open menu"
					icon={<FiMenu />}
				/>
				{auth.user.business_type === "hotel" ? <Box></Box> : <ManageOrder />}

				<HStack spacing={{ base: "0", md: "6" }}>
					<ProfilePopover />
				</HStack>
			</Flex>
		</>
	);
};

function WelcomeModal({ isOpen, onClose }) {
	return (
		<Modal isOpen={isOpen} onClose={onClose} size={"2xl"} isCentered>
			<ModalOverlay />
			<ModalContent textAlign={"center"}>
				<ModalHeader color={"cyan.800"}>ยินดีต้อนรับ</ModalHeader>
				<ModalBody pb={4}>
					<VStack spacing={4}>
						<Text fontSize="lg">เรายินดีที่คุณสนใจและสมัครใช้งานเข้ามา!</Text>
						<Text fontSize="lg" fontWeight="bold">
							หากมีข้อสงสัยหรือพบปัญหาในการใช้งาน อย่าลังเลที่จะติดต่อเรา
						</Text>
						<Text fontSize="lg">
							หากคุณมีข้อเสนอแนะหรือต้องการแนะนำเพิ่มเติมเกี่ยวกับการใช้งาน
							เราพร้อมรับฟังทุกความคิดเห็น
							เพื่อที่จะพัฒนาและปรับปรุงประสบการณ์การใช้งานให้ดียิ่งขึ้น
						</Text>
						<Text fontSize="lg" fontWeight="bold">
							คุณสามารถติดต่อเราผ่านช่องทางต่อไปนี้
						</Text>
					</VStack>
					<Flex
						justifyContent="center"
						alignItems="center"
						fontSize="lg"
						gap={1}
					>
						อีเมล: <Text color="blue.500">sevscan@gmail.com</Text>
					</Flex>
					<Flex
						justifyContent="center"
						alignItems="center"
						fontSize="lg"
						gap={1}
					>
						หรือแอดมาที่ไลน์: <Text color="blue.400">@sevscan</Text>
					</Flex>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
}
