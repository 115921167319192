import React, { useState, useMemo, useEffect, useContext } from "react";
import axios from "axios";
import {
	Flex,
	Box,
	Card,
	Container,
	useToast,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	Input,
	ModalFooter,
	Button,
	useDisclosure,
	SimpleGrid,
	FormLabel,
	Link,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { API_BASE_URL } from "../../config/apiConfig";
import { useAuth } from "../../hooks/AuthProvider";
import ShopContext from "../../hooks/ShopContext";

function Employee() {
	const { shopValueId } = useContext(ShopContext);
	const toast = useToast();
	const auth = useAuth();
	const headers = useMemo(
		() => ({
			Authorization: auth.user.token,
		}),
		[auth.user.token]
	);

	const [employee, setEmployee] = useState();
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.post(
					`${API_BASE_URL}/api/site/employee`,
					{ shopValueId },
					{ headers }
				);
				// console.log("em", response.data.results);
				setEmployee(response.data.results[0]);
			} catch (error) {
				console.error("Error get employee:", error);
			}
		};

		if (shopValueId && headers) {
			fetchData();
		}
	}, [toast, headers, shopValueId]);

	const { isOpen, onOpen, onClose } = useDisclosure();
	const [password, setPassword] = useState();
	const handleEdit = (data) => {
		// console.log(data);
		setPassword(data.password);
		onOpen();
	};
	const handleSave = async () => {
		const trimmedPassword = password.trim();
		// console.log(trimmedPassword);

		if (!trimmedPassword) {
			toast({
				title: `กรอกรหัสผ่าน`,
				position: "top",
				isClosable: true,
				status: "warning",
				variant: "top-accent",
				duration: 5000,
			});
			return;
		}

		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/site/employee_update_password`,
				{
					editId: employee.id,
					trimmedPassword,
				},
				{ headers }
			);
			if (response.data.message === "success") {
				setEmployee((prevEmployee) => ({
					...prevEmployee,
					password: trimmedPassword,
				}));
				onClose();
				toast({
					title: `เรียบร้อย`,
					position: "top",
					isClosable: true,
					status: "success",
					variant: "top-accent",
					duration: 5000,
				});
			} else {
				console.error("Error category_add");
			}
		} catch (error) {
			console.error("Error saving category:", error);
		}
	};

	return (
		<>
			<Flex
				height={{ base: "auto", md: "auto", lg: "calc(100vh - 10em)" }}
				justifyContent={{ base: "auto", md: "center" }}
				alignItems={{ base: "auto", md: "center" }}
			>
				<Container maxW={"3xl"} px={"none"}>
					<Card boxShadow={"none"} borderRadius={"1em"}>
						<Box
							fontSize="1em"
							mb={4}
							color={"white"}
							py={3}
							borderTopRadius={"1em"}
							textAlign={"center"}
							bgColor={"cyan.700"}
							fontWeight={"bold"}
						>
							พนักงานรับออเดอร์
						</Box>
						<Box p={{ base: 6, md: 10 }} textAlign={"center"}>
							<SimpleGrid
								columns={{ base: 1, md: 2 }}
								spacing={{ base: 3, md: 5 }}
							>
								<Box fontWeight={"bold"} fontSize={"lg"} color={"cyan.700"}>
									หน้าเข้าใช้
								</Box>
								<Box color={"gray.500"} pb={{ base: 3, md: 0 }}>
									<Link href={`/em`} isExternal>
										www.sevscan.com/em
									</Link>
								</Box>
								<Box fontWeight={"bold"} fontSize={"lg"} color={"cyan.700"}>
									รหัสร้าน
								</Box>
								<Box color={"gray.500"} pb={{ base: 3, md: 0 }}>
									{employee?.code}
								</Box>
								<Box fontWeight={"bold"} fontSize={"lg"} color={"cyan.700"}>
									รหัสผ่านพนักงาน
								</Box>
								<Box color={"gray.500"} pb={{ base: 3, md: 0 }}>
									{employee?.password}
								</Box>
							</SimpleGrid>
						</Box>
						<Box textAlign={"right"} px={10} pb={5}>
							<EditIcon
								onClick={() => handleEdit(employee)}
								color="cyan.700"
								cursor="pointer"
							/>
						</Box>
					</Card>
				</Container>
			</Flex>

			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalCloseButton />
					<ModalBody>
						<FormLabel>เปลี่ยนรหัสผ่านพนักงาน</FormLabel>
						<Input
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="red" onClick={onClose} mr={2}>
							ยกเลิก
						</Button>
						<Button colorScheme="telegram" onClick={() => handleSave()}>
							ยืนยัน
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}

export default Employee;
