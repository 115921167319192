import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_BASE_URL } from "../config/apiConfig";
import {
	Stack,
	Flex,
	Box,
	Image,
	Heading,
	Text,
	SimpleGrid,
	ScaleFade,
	Button,
	useToast,
	Container,
} from "@chakra-ui/react";
import CardPrice from "./component/CardPrice";
import RegisterButton from "./component/RegisterButton";
import BgImg from "../images/bg_monitor.jpg";
import Main1 from "../images/acc/main1.jpg";
import Main2 from "../images/acc/main2.jpg";
import Main3 from "../images/acc/main3.jpg";
import Main4 from "../images/acc/main4.jpg";
import Main5 from "../images/acc/main5.jpg";
import Main6 from "../images/acc/main6.jpg";
import Img1 from "../images/acc/1.jpg";
import Img2 from "../images/acc/2.jpg";
import Img3 from "../images/acc/3.jpg";
import Img4 from "../images/acc/4.jpg";
import Img5 from "../images/acc/5.jpg";
import Img6 from "../images/acc/6.jpg";
import Img7 from "../images/acc/7.jpg";
import Img8 from "../images/acc/8.jpg";
import Img9 from "../images/acc/9.jpg";
import Img10 from "../images/acc/10.jpg";

function Acc() {
	const toast = useToast();
	const [mainImg, setMainImg] = useState("");
	const [showMain, setShowMain] = useState(true);

	useEffect(() => {
		const mainImages = [Main1, Main2, Main3, Main4, Main5, Main6];

		const updateImage = () => {
			const randomQuote =
				mainImages[Math.floor(Math.random() * mainImages.length)];
			setShowMain(false);
			setTimeout(() => {
				setMainImg(randomQuote);
				setShowMain(true);
			}, 10);
		};

		updateImage();

		const intervalId = setInterval(() => {
			updateImage();
		}, 4000);

		return () => clearInterval(intervalId);
	}, []);

	const topImages = [Img6, Img7, Img8, Img9, Img10];
	const bottomImages = [Img1, Img2, Img3, Img4, Img5];

	const renderImages = (images) =>
		images.map((src, index) => (
			<Image
				key={index}
				src={src}
				w="full"
				borderRadius={{ base: "0.5em", md: "1em" }}
			/>
		));

	const [price, setPrice] = useState();
	useEffect(() => {
		const getPricePromotion = async () => {
			try {
				const response = await axios.get(
					`${API_BASE_URL}/api/marketing/price_promotion`
				);
				setPrice(response.data.results[0].price);
			} catch (error) {
				toast({
					title: `ไม่สามารถติดต่อระบบได้`,
					description: `กรุณาติดต่อพนักงาน`,
					position: "top",
					isClosable: true,
					status: "error",
					variant: "top-accent",
					duration: 5000,
				});
			}
		};
		getPricePromotion();
	}, [toast]);

	return (
		<>
			<Flex
				bgImage={BgImg}
				w="full"
				alignItems="center"
				justifyContent="center"
				minH="100vh"
				py={{ base: 1, md: 5, xl: 10 }}
			>
				<Container
					maxW="container.lg"
					bgColor={"white"}
					p={0}
					boxShadow="xl"
					borderRadius="1em"
				>
					<Box p={{ base: 1, md: 3, xl: 4 }}>
						<SimpleGrid columns={5} spacing={{ base: 1, md: 2 }}>
							{renderImages(topImages)}
						</SimpleGrid>
						<Box py={{ base: 1, md: 2 }}>
							<ScaleFade initialScale={0.9} in={showMain}>
								<Image
									src={mainImg}
									w="full"
									borderRadius={{ base: "0.5em", md: "1em" }}
								/>
							</ScaleFade>
						</Box>
						<SimpleGrid columns={5} spacing={{ base: 1, md: 2 }}>
							{renderImages(bottomImages)}
						</SimpleGrid>
					</Box>
					<Box px={{ base: 1, md: 3, xl: 0 }}>
						<CardPrice price={price} />
						<Box
							display={{ lg: "flex" }}
							alignItems={{ lg: "center" }}
							justifyContent={{ lg: "space-between" }}
							bgColor="gray.50"
							px={{ base: 3, md: 6, xl: 8 }}
							py={{ base: 5, md: 8, xl: 8 }}
						>
							<Heading
								as="h2"
								fontSize={{ base: "2xl", sm: "2xl", md: "3xl", lg: "4xl" }}
								letterSpacing="tight"
								color="gray.900"
								_dark={{ color: "gray.100" }}
							>
								<Text display="block" fontWeight="600">
									สมัครใช้งาน
								</Text>
								<Text
									fontWeight="400"
									fontSize={{ base: 18, md: 26 }}
									color="gray.600"
									_dark={{ color: "gray.500" }}
								>
									ทดลองใช้งานฟรีเต็มรูปแบบ 30 วันได้เลยตอนนี้
								</Text>
							</Heading>
							<Stack
								direction={"row"}
								mt={{ base: 6, lg: 0 }}
								flexShrink={{ lg: 0 }}
								spacing={3}
								alignItems="center"
								justifyContent="center"
							>
								<Button
									color={"cyan.700"}
									bg={"white"}
									_dark={{ bg: "gray.600" }}
									fontWeight={"normal"}
									boxShadow={"md"}
									_hover={{ bg: "gray.200" }}
									onClick={() => window.open("./demo/shop02demo", "_blank")}
								>
									เมนูตัวอย่าง
								</Button>
								<RegisterButton price="auto" businessType="hotel" />
							</Stack>
						</Box>
					</Box>
				</Container>
			</Flex>
		</>
	);
}

export default Acc;
