import React, { useMemo, useEffect, useState } from "react";
import axios from "axios";
import {
	Box,
	Flex,
	Card,
	Button,
	useDisclosure,
	Drawer,
	DrawerBody,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	DrawerHeader,
	Image,
	SimpleGrid,
	Input,
	Text,
	useToast,
	Stack,
	Badge,
	Select,
} from "@chakra-ui/react";
import { BeatLoader } from "react-spinners";
import { useAuth } from "../../hooks/AuthProvider";
import { API_BASE_URL } from "../../config/apiConfig";
import BBL_Promptpay from "../../images/payment/BBL_Promptpay.jpg";

function Renew() {
	const auth = useAuth();
	const headers = useMemo(
		() => ({
			Authorization: auth.user.token,
		}),
		[auth.user.token]
	);

	const [lastDate, setLastDate] = useState();
	const [paymentData, setPaymentData] = useState({
		plan: "",
		image_payment: "",
		date_before_payment: "",
		last_day: "",
	});
	const [updateDateShow, setUpdateDateShow] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.post(
					`${API_BASE_URL}/api/site/last_date`,
					{},
					{ headers }
				);

				// console.log(response.data);
				const res = response.data.results[0];
				setLastDate(res.last_day);
				setPaymentData({
					plan: res.plan,
					image_payment: res.image_payment,
					date_before_payment: res.date_before_payment,
					last_day: res.last_day,
				});
			} catch (error) {
				console.error("Error last_date:", error);
			}
		};

		if (headers) {
			fetchData();
		}
		if (updateDateShow) {
			fetchData();
			setUpdateDateShow(false);
		}
	}, [headers, updateDateShow]);

	const convertToThaiDate = (dateTimeString) => {
		// console.log(dateTimeString);
		const dateTime = new Date(dateTimeString);
		const options = {
			year: "numeric",
			month: "long",
			day: "numeric",
			hour: "numeric",
			minute: "numeric",
			second: "numeric",
		};
		const thaiDateTime = dateTime.toLocaleString("th-TH", options);
		return thaiDateTime;
	};

	return (
		<>
			<Flex direction={{ base: "column", lg: "row" }} gap={7}>
				<Box flex={{ lg: 5, xl: 4 }}>
					<Box>
						<Card boxShadow={"none"} borderRadius={"1em"} textAlign={"center"}>
							<Box
								fontSize="1em"
								color={"white"}
								py={4}
								borderTopRadius={"1em"}
								bgColor={"cyan.700"}
								fontWeight={"bold"}
							>
								อายุการใช้งานถึง
							</Box>
							<Box
								pt={{ base: 7, md: 10 }}
								pb={{ base: 5, md: 8 }}
								fontSize={"lg"}
								color={paymentData.plan !== null ? "orange" : "gray.600"}
							>
								{lastDate && convertToThaiDate(lastDate)}
								<Box display={auth.user.period === "demo" ? "block" : "none"}>
									<Badge colorScheme="red">ทดลองฟรี 30 วัน</Badge>
								</Box>
							</Box>
							<Box pb={5} textAlign={"center"} px={5}>
								<DrawerRenew
									setUpdateDateShow={setUpdateDateShow}
									paymentData={paymentData}
								/>
							</Box>
						</Card>
					</Box>
				</Box>
				<Box flex={{ lg: 7, xl: 8 }}>
					{paymentData.plan && (
						<>
							<Card p={10}>
								<SimpleGrid columns={{ base: 1, xl: 2 }} spacing={10} mt={4}>
									<Image
										w={"full"}
										src={`${API_BASE_URL}/image/payment/${paymentData.image_payment}`}
									/>
									<Box>
										<Box borderWidth="1px" borderRadius={"md"} p={5}>
											<Text>แผนต่ออายุ : {paymentData.plan} ปี</Text>
											<Flex gap={1.5}>
												วันสิ้นสุดเดิม :{" "}
												<Text>
													{convertToThaiDate(paymentData.date_before_payment)}
												</Text>
											</Flex>
											<Flex gap={1.5}>
												วันสิ้นสุดใหม่ :{" "}
												<Text>{convertToThaiDate(paymentData.last_day)}</Text>
											</Flex>
											<Flex gap={1.5} mb={4}>
												สถานะ :{" "}
												<Text color={"orange"} fontWeight={"bold"}>
													รอตรวจสอบ
												</Text>
											</Flex>
											<Text color={"green"} as={"i"}>
												ระหว่างรอตรวจสอบสามารถใช้งานได้ปกติ
											</Text>
										</Box>
									</Box>
								</SimpleGrid>
							</Card>
						</>
					)}
				</Box>
			</Flex>
		</>
	);
}

function DrawerRenew({ setUpdateDateShow, paymentData }) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const btnRef = React.useRef();

	return (
		<>
			<Button
				ref={btnRef}
				fontWeight={"md"}
				size={{ base: "sm", md: "md" }}
				borderRadius={"full"}
				border={"1.5px solid"}
				borderColor={"cyan.700"}
				color={"cyan.800"}
				bgColor={"#d0e7ec"}
				_hover={"none"}
				onClick={onOpen}
				display={paymentData.plan === null ? "inline-block" : "none"}
			>
				ต่ออายุ
			</Button>
			<Drawer
				isOpen={isOpen}
				placement="right"
				onClose={onClose}
				finalFocusRef={btnRef}
				size={"lg"}
			>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>ต่ออายุการใช้งาน</DrawerHeader>

					<DrawerBody>
						<Image src={BBL_Promptpay} w={{ base: "full", md: "80%" }} />
						<Text as={"i"} textAlign={"right"} fontSize={"sm"}>
							บัญชีธนาคารผู้พัฒนาระบบ
						</Text>
						<Box>
							<RenewDetail
								onClose={onClose}
								setUpdateDateShow={setUpdateDateShow}
							/>
						</Box>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	);
}

function RenewDetail({ onClose, setUpdateDateShow }) {
	const auth = useAuth();
	const headers = useMemo(
		() => ({
			Authorization: auth.user.token,
		}),
		[auth.user.token]
	);
	const toast = useToast();

	const [planNumberDate, setPlanNumberDate] = useState(1);
	const [image, setImage] = useState(null);
	const [isLoadingSave, setIsLoadingSave] = useState(false);

	const handleImageUpload = (event) => {
		const file = event.target.files[0];
		if (file && file.type.startsWith("image/")) {
			setImage(file);
		} else {
			setImage(null);
			alert("Please upload a valid image file.");
		}
	};

	const handleSubmit = async () => {
		if (image && planNumberDate) {
			setIsLoadingSave(true);
			// console.log("Image uploaded:", image);

			const formData = new FormData();
			formData.append("plan", planNumberDate);
			formData.append("image", image);

			const response = await axios.post(
				`${API_BASE_URL}/api/site/renew_update`,
				formData,
				{ headers }
			);
			// console.log(response.data);
			if (response.data.message === "success") {
				toast({
					title: `ส่งสลิปโอนเงินแล้ว`,
					position: "top",
					isClosable: true,
					status: "success",
					variant: "top-accent",
					duration: 5000,
				});
				onClose();
				setUpdateDateShow(true);

				const user = auth.user;
				user.period = "real";
				localStorage.setItem("user", JSON.stringify(user));
			} else {
				console.error("Error shop detial/update");
			}
		} else {
			toast({
				title: `เลือกจำนวนปีและเพิ่มภาพสลิปการโอนเงินก่อนบันทึก`,
				position: "top",
				isClosable: true,
				status: "warning",
				variant: "top-accent",
				duration: 5000,
			});
		}
	};

	const [price, setPrice] = useState();
	useEffect(() => {
		const getPricePromotion = async () => {
			try {
				const response = await axios.post(
					`${API_BASE_URL}/api/site/my_price_promotion`,
					{},
					{ headers }
				);
				setPrice(response.data.results[0].price);
			} catch (error) {
				toast({
					title: `ไม่สามารถติดต่อระบบได้`,
					description: `กรุณาติดต่อพนักงาน`,
					position: "top",
					isClosable: true,
					status: "error",
					variant: "top-accent",
					duration: 5000,
				});
			}
		};
		getPricePromotion();
	}, [toast, headers]);

	return (
		<>
			<Stack direction="row" spacing={2} mt={7}>
				<Select
					w={"full"}
					_hover={"none"}
					variant="filled"
					colorScheme="teal"
					value={planNumberDate}
					onChange={(e) => setPlanNumberDate(Number(e.target.value))}
				>
					<option value={1}>ต่ออายุ 1 ปี</option>
					<option value={2}>ต่ออายุ 2 ปี ส่วนลด -10%</option>
					<option value={3}>ต่ออายุ 3 ปี ส่วนลด -20%</option>
				</Select>
			</Stack>
			<Box mt={2} p={4} borderWidth="1px" borderRadius={"md"}>
				<Flex gap={2}>
					<Text>ยอดโอน </Text>
					{planNumberDate === 1 ? (
						""
					) : (
						<Text as="s">{(planNumberDate * price)?.toFixed(2)}</Text>
					)}
					<Text>
						{planNumberDate === 3
							? (planNumberDate * price * 0.8)?.toFixed(2)
							: planNumberDate === 2
							? (planNumberDate * price * 0.9)?.toFixed(2)
							: price?.toFixed(2)}
					</Text>
					บาท
				</Flex>
			</Box>
			<Text mt={5}>เพิ่มภาพสลิปการโอนเงิน</Text>
			<Box>
				<Input
					w={"full"}
					h={"4em"}
					pt={4}
					id="file-upload"
					type="file"
					accept=".png, .jpg, .jpeg"
					onChange={handleImageUpload}
				/>
			</Box>
			<Box mt={4} textAlign={"right"}>
				<Button
					colorScheme="blue"
					onClick={handleSubmit}
					isLoading={isLoadingSave}
					spinner={<BeatLoader size={8} color="white" />}
				>
					ส่งข้อมูล
				</Button>
			</Box>
		</>
	);
}

export default Renew;
