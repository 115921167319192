import React, { useState } from "react";
import {
	Container,
	SimpleGrid,
	Box,
	Text,
	VStack,
	Checkbox,
	CheckboxGroup,
	Stack,
	Image,
	Spinner,
} from "@chakra-ui/react";
import Image1 from "../images/pmanual/1.jpg";
import Image2 from "../images/pmanual/2.jpg";
import Image3 from "../images/pmanual/3.jpg";
import Image4 from "../images/pmanual/4.jpg";
import Image5 from "../images/pmanual/5.jpg";
import Image6 from "../images/pmanual/6.jpg";
import Image7 from "../images/pmanual/7.jpg";
import Image9 from "../images/pmanual/9.jpg";
import Image10 from "../images/pmanual/10.jpg";
import Image15 from "../images/pmanual/15.jpg";
import Image16 from "../images/pmanual/16.jpg";
import Image18 from "../images/pmanual/18.jpg";

const images = [
	{ src: Image5, title: "จัดการเมนูสินค้า" },
	{ src: Image10, title: "รับออเดอร์หน้าร้าน" },
	{ src: Image4, title: "พนักงานจดบันทึกออเดอร์" },
	{ src: Image3, title: "สั่งซื้อด้วย QR Menu" },
	{ src: Image6, title: "แจ้งเตือนรายการผ่าน LINE" },
	{ src: Image7, title: "ติดตามยอดขายออนไลน์" },
	{ src: Image9, title: "พิมพ์สลิปผ่านหน้าเว็บ(2 ภาษา)" },
	{ src: Image2, title: "ปรับเปลี่ยนโลโก้ร้าน" },
	{ src: Image1, title: "สลับ 2 ภาษา" },
	{ src: Image15, title: "แนะนำเมนูผ่านโซเชียลมีเดีย" },
	{ src: Image16, title: "ป้ายหน้าร้าน" },
	{ src: Image18, title: "สะดวกใช้งานบนเบราว์เซอร์" },
];

function PageManual() {
	return (
		<Box py="20" minH="calc(100vh - 200px)">
			<Container maxW="container.xl">
				<SimpleGrid
					columns={{ base: 1, md: 2, lg: 3, xl: 4 }}
					spacing={{ base: 5, md: 6, lg: 7, xl: 8 }}
				>
					{images.map((image, index) => (
						<Box key={index}>
							<ImageCard src={image.src} title={image.title} />
						</Box>
					))}
				</SimpleGrid>
			</Container>
			<Container maxW="container.xl" display={"none"}>
				<DataList />
			</Container>
		</Box>
	);
}

const ImageCard = ({ src, title }) => {
	const [isLoading, setIsLoading] = useState(true);

	const handleImageLoad = () => {
		setIsLoading(false);
	};

	return (
		<Box
			bg="white"
			_dark={{
				bg: "gray.800",
			}}
			maxW="sm"
			rounded="lg"
			shadow="lg"
		>
			<Box position="relative" minH={"270px"}>
				{isLoading && (
					<Box
						display="flex"
						alignItems="center"
						justifyContent="center"
						position="absolute"
						top="0"
						left="0"
						width="100%"
						height="100%"
						bg="rgba(255, 255, 255, 0.8)"
						roundedTop="lg"
					>
						<Spinner size="sm" />
					</Box>
				)}
				<Image src={src} alt={title} roundedTop="lg" onLoad={handleImageLoad} />
			</Box>
			<Box p="4">
				<Text
					fontWeight="semibold"
					as="h4"
					lineHeight="tight"
					noOfLines={1}
					_dark={{
						color: "white",
					}}
					textAlign={"center"}
				>
					{title}
				</Text>
			</Box>
		</Box>
	);
};

const DataList = () => {
	const [filterName, setFilterName] = useState([]);
	const [filterValue, setFilterValue] = useState([]);
	const [filterDetail, setFilterDetail] = useState([]);
	const [filterDetail2, setFilterDetail2] = useState([]);

	const data = [
		{ name: "a", value: [1, 2], detail: ["x", "y"], detail2: ["u", "i"] },
		{
			name: "b",
			value: [2, 3, 4],
			detail: ["x", "y", "z"],
			detail2: ["u", "i", "m"],
		},
		{ name: "b", value: [2, 4], detail: ["y"], detail2: ["m", "i"] },
	];

	const handleFilterNameChange = (selectedValues) => {
		setFilterName(selectedValues);
	};

	const handleFilterValueChange = (selectedValues) => {
		setFilterValue(selectedValues);
	};

	const handleFilterDetailChange = (selectedValues) => {
		setFilterDetail(selectedValues);
	};

	const handleFilterDetail2Change = (selectedValues) => {
		setFilterDetail2(selectedValues);
	};

	const filteredData = data.filter((item) => {
		const matchName = filterName.length === 0 || filterName.includes(item.name);
		const matchValue =
			filterValue.length === 0 ||
			filterValue.some((value) => item.value.includes(Number(value)));
		const matchDetail =
			filterDetail.length === 0 ||
			filterDetail.some((detail) => item.detail.includes(detail));
		const matchDetail2 =
			filterDetail2.length === 0 ||
			filterDetail2.some((detail) => item.detail2.includes(detail));

		return matchName && matchValue && matchDetail && matchDetail2;
	});

	return (
		<VStack spacing={4} align="stretch">
			<Stack direction={["column", "row"]} spacing={10}>
				<Box>
					<Text fontWeight="bold">Filter by Name</Text>
					<CheckboxGroup onChange={handleFilterNameChange}>
						<VStack align="flex-start">
							{[...new Set(data.map((item) => item.name))].map(
								(name, index) => (
									<Checkbox key={index} value={name}>
										{name}
									</Checkbox>
								)
							)}
						</VStack>
					</CheckboxGroup>
				</Box>
				<Box>
					<Text fontWeight="bold">Filter by Value</Text>
					<CheckboxGroup onChange={handleFilterValueChange}>
						<VStack align="flex-start">
							{[...new Set(data.flatMap((item) => item.value))].map(
								(value, index) => (
									<Checkbox key={index} value={value.toString()}>
										{value}
									</Checkbox>
								)
							)}
						</VStack>
					</CheckboxGroup>
				</Box>
				<Box>
					<Text fontWeight="bold">Filter by Detail</Text>
					<CheckboxGroup onChange={handleFilterDetailChange}>
						<VStack align="flex-start">
							{[...new Set(data.flatMap((item) => item.detail))].map(
								(detail, index) => (
									<Checkbox key={index} value={detail}>
										{detail}
									</Checkbox>
								)
							)}
						</VStack>
					</CheckboxGroup>
				</Box>
				<Box>
					<Text fontWeight="bold">Filter by Detail2</Text>
					<CheckboxGroup onChange={handleFilterDetail2Change}>
						<VStack align="flex-start">
							{[...new Set(data.flatMap((item) => item.detail2))].map(
								(detail2, index) => (
									<Checkbox key={index} value={detail2}>
										{detail2}
									</Checkbox>
								)
							)}
						</VStack>
					</CheckboxGroup>
				</Box>
			</Stack>

			{filteredData.map((item, index) => (
				<Box key={index} p={4} borderWidth="1px" borderRadius="lg">
					<Text fontSize="lg" fontWeight="bold">
						{item.name}
					</Text>
					<Text>Value: {item.value.join(", ")}</Text>
					<Text>Detail: {item.detail.join(", ")}</Text>
					<Text>Detail2: {item.detail2.join(", ")}</Text>
				</Box>
			))}
		</VStack>
	);
};

export default PageManual;
