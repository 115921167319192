import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalCloseButton,
	Table,
	Tbody,
	Tr,
	Td,
	Box,
	SimpleGrid,
	Text,
	Spinner,
	useToast,
	useColorModeValue,
	IconButton,
	Divider,
	Flex,
	Image,
} from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import { PiPrinterDuotone, PiCarProfileDuotone } from "react-icons/pi";
import { TiDelete } from "react-icons/ti";
import { useAuth } from "../../../hooks/AuthProvider";
import { API_BASE_URL } from "../../../config/apiConfig";

const thaiDateTime = (dateTimeString, language) => {
	const dateTime = new Date(dateTimeString);
	const options = {
		year: "numeric",
		month: "long",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
		second: "numeric",
		hour12: false,
	};
	const thaiDateTime = dateTime.toLocaleDateString(language, options);
	return thaiDateTime;
};

const BillDetailModalAdmin = ({
	isOpen,
	onClose,
	billDetail,
	loading,
	modalSize,
	nowUnitName,
	setBillDetail,
	shopValueId,
}) => {
	// console.log(billDetail);
	const toast = useToast();
	const bgCardColor = useColorModeValue("gray.50", "gray.600");
	const textColor = useColorModeValue("gray.600", "gray.400");
	const textSumColor = useColorModeValue("cyan.700", "gray.100");
	const auth = useAuth();
	const headers = useMemo(
		() => ({
			Authorization: auth.user.token,
		}),
		[auth.user.token]
	);

	const [totalPrice, setTotalPrice] = useState();
	useEffect(() => {
		const ttp = billDetail.reduce(
			(sum, item) => sum + parseFloat(item.total_price),
			0
		);
		// console.log("ttp", ttp, billDetail);
		setTotalPrice(ttp);
	}, [billDetail]);

	const handleDelete = (detail, order_id) => {
		const parts = detail.split(":");
		// console.log(detail, parts, order_id);

		Swal.fire({
			html: `ลบ "${parts[1]}" ออก`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#109ebe",
			cancelButtonColor: "#c24a4a",
			cancelButtonText: "ยกเลิก",
			confirmButtonText: "ยืนยัน",
			reverseButtons: true,
			showClass: {
				popup: "swal2-noanimation", // No animation for showing
			},
			hideClass: {
				popup: "", // No animation for hiding
			},
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					const response = await axios.post(
						`${API_BASE_URL}/api/employee/order_detail_delete`,
						{ delete_id: parts[0], order_id },
						{ headers }
					);
					// console.log(response);
					if (response.data.message === "success") {
						const updatedBillDetail = billDetail
							.map((item) => {
								// console.log("top", item.order_details);
								if (item.order_details) {
									const parts = item.order_details.split(";");
									const filteredParts = parts.filter((part) => part !== detail);
									const updatedOrderDetails = filteredParts.join(";");

									let totalPrice = 0;
									filteredParts.forEach((subitem) => {
										const [, , , price, quantity] = subitem.split(":");
										totalPrice += parseFloat(price) * parseInt(quantity, 10);
									});

									if (updatedOrderDetails !== "") {
										return {
											...item,
											order_details: updatedOrderDetails,
											total_price: totalPrice,
										};
									} else {
										return null;
									}
								} else {
									return item;
								}
							})
							.filter(Boolean);
						setBillDetail(updatedBillDetail);
						// console.log("updatedBillDetail", updatedBillDetail);
					} else {
						toast({
							title: `ไม่สามารถลบข้อมูลได้`,
							position: "top",
							isClosable: true,
							status: "warning",
							variant: "top-accent",
							duration: 2000,
						});
					}
				} catch (error) {
					console.error("Error order_detail_delete:", error);
				}
			}
		});
	};

	const formatValue = (value) => {
		return value.endsWith(".00") ? parseInt(value) : value;
	};

	const [showReceipt, setShowReceipt] = useState(false);
	const [shopData, setShopData] = useState();
	const [displayLogo, setDisplayLogo] = useState("default.png");
	const swidthReceipt = () => {
		getShopData();
		setShowReceipt(true);
	};
	const handleClose = () => {
		setShowReceipt(false);
		onClose();
	};
	const getShopData = async () => {
		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/shopping/shop_data`,
				{ shopValueId },
				{}
			);
			setShopData(response.data.results[0]);
			if (response.data.results[0].logo !== "") {
				setDisplayLogo(response.data.results[0].logo);
			}
		} catch (error) {
			toast({
				title: `ไม่สามารถติดต่อระบบได้`,
				position: "top",
				isClosable: true,
				status: "error",
				variant: "top-accent",
				duration: 5000,
			});
		}
	};
	const [currentDateTime, setCurrentDateTime] = useState("");
	const [nowLanguage, setNowLanguage] = useState("th");
	const languageIcons = {
		en: "emojione:flag-for-united-kingdom",
		th: "emojione:flag-for-thailand",
		cn: "emojione:flag-for-china",
	};

	useEffect(() => {
		const updateDateTime = () => {
			const now = new Date();
			const options = {
				year: "numeric",
				month: "long",
				day: "numeric",
				hour: "numeric",
				minute: "numeric",
				second: "numeric",
				hour12: false,
			};
			const formattedDateTime = now.toLocaleDateString(
				nowLanguage === "th" ? "th-TH" : "en-EN",
				options
			);
			setCurrentDateTime(formattedDateTime);
		};

		if (nowLanguage) {
			updateDateTime();
		}
	}, [nowLanguage]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={handleClose}
			size={{ base: "full", md: modalSize }}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalBody px={2} pt={0}>
					{showReceipt ? (
						<>
							<Box mt={5}>
								<div id="print-area">
									<Box px={1}>
										<Box textAlign="center" mb={2}>
											<Image
												src={`${API_BASE_URL}/image/logo/${displayLogo}`}
												width="80px"
												mx="auto"
											/>
											<Text fontWeight="bold" fontSize="2xl" mt={1}>
												{nowLanguage === "th"
													? shopData?.name
													: shopData?.name_en}
											</Text>
											<Text fontSize="xl">
												{nowLanguage === "th"
													? nowUnitName.name
													: nowUnitName.name_en !== ""
													? nowUnitName.name_en
													: nowUnitName.name}
											</Text>
											<Text fontSize="lg">{currentDateTime}</Text>
										</Box>
										<Box>
											<Flex justify="center">
												<Divider
													mt={2}
													mb={8}
													variant="dashed"
													borderWidth="1px"
													w="80%"
													borderColor="black"
												/>
											</Flex>
										</Box>
										<Table fontSize={"lg"}>
											<Tbody>
												{billDetail.map((order, index) => (
													<>
														{order.order_details.split(";").map((detail, i) => {
															const [
																,
																productName,
																productNameEn,
																price,
																quantity,
															] = detail.split(":");
															const countPrice = (
																parseFloat(price) * parseInt(quantity)
															).toFixed(2);
															return (
																<Tr
																	key={i}
																	fontWeight={"300"}
																	color={"gray.600"}
																>
																	<Td
																		border={"none"}
																		style={{ WebkitPaddingStart: "0px" }}
																	>
																		{nowLanguage === "th"
																			? productName
																			: productNameEn !== ""
																			? productNameEn
																			: productName}
																	</Td>
																	<Td
																		border={"none"}
																		textAlign={"right"}
																		style={{
																			WebkitPaddingStart: "0px",
																			WebkitPaddingEnd: "0px",
																		}}
																	>
																		{formatValue(price)} x {quantity}
																	</Td>
																	<Td
																		border={"none"}
																		textAlign={"right"}
																		style={{
																			WebkitPaddingStart: "0px",
																			WebkitPaddingEnd: "0px",
																		}}
																	>
																		<Text>{countPrice}</Text>
																	</Td>
																</Tr>
															);
														})}
													</>
												))}
											</Tbody>
										</Table>
										<Divider
											mt={0}
											mb={3}
											variant={"dashed"}
											borderWidth="1px"
											borderColor="black"
										/>
										<SimpleGrid
											columns={2}
											spacing={0}
											fontWeight={"600"}
											color={"gray.700"}
											fontSize={"xl"}
										>
											<Text>{nowLanguage === "th" ? "รวม" : "Total"}</Text>
											<Text textAlign={"right"}>
												{totalPrice.toFixed(2)}{" "}
												{nowLanguage === "th" ? "บาท" : "baht"}
											</Text>
										</SimpleGrid>
									</Box>
									<Box onClick={handleClose} cursor="pointer" mt={5}>
										<Box
											display="flex"
											justifyContent="center"
											alignItems="center"
											fontSize={"lg"}
										>
											<Text>Thank You</Text>
										</Box>
										<Box
											display="flex"
											justifyContent="center"
											alignItems="center"
											fontSize={"3xl"}
											pb={4}
										>
											..
											<Flex>
												<PiCarProfileDuotone size={"1em"} />
											</Flex>
										</Box>
										<Box
											display="flex"
											justifyContent="center"
											alignItems="center"
										>
											<IconButton
												ml={3}
												w={"fit-content"}
												variant={"ghost"}
												aria-label="Print"
												icon={<PiPrinterDuotone />}
												onClick={() => window.print()}
												display="none"
											/>
										</Box>
									</Box>
								</div>
							</Box>
						</>
					) : (
						<>
							{loading ? (
								<Box
									minH={"100px"}
									gridColumn="1 / -1"
									display="flex"
									justifyContent="center"
									alignItems="center"
								>
									<Spinner size="sm" />
								</Box>
							) : (
								<>
									<Flex pl={1} pt={2}>
										<Text color={"cyan.700"} fontWeight={"bold"} mt={1}>
											{nowLanguage === "th"
												? nowUnitName.name
												: nowUnitName.name_en !== ""
												? nowUnitName.name_en
												: nowUnitName.name}
										</Text>
										<IconButton
											ml={3}
											mt={-1}
											variant={"ghost"}
											aria-label="Print"
											color={"gray.700"}
											_dark={{ color: "gray.100" }}
											icon={
												<Icon
													icon={languageIcons[nowLanguage]}
													fontSize={"19"}
												/>
											}
											onClick={() =>
												setNowLanguage(nowLanguage === "th" ? "en" : "th")
											}
											fontSize={23}
											display={"none"}
										/>
										<IconButton
											ml={1}
											mt={-1}
											variant={"ghost"}
											aria-label="Print"
											color={"blue.600"}
											_dark={{ color: "gray.100" }}
											icon={<PiPrinterDuotone />}
											onClick={() => swidthReceipt()}
											fontSize={23}
											display={"none"}
										/>
										<ModalCloseButton />
									</Flex>
									<Box mb={modalSize === "full" ? 12 : 3}>
										<Box p={2} bgColor={bgCardColor} borderRadius={"1em"}>
											<Table size={{ base: "sm", md: "md" }}>
												<Tbody>
													{billDetail.map((order, index) => (
														<>
															<Tr
																color={"black"}
																_dard={{ color: "white" }}
																fontWeight={"bold"}
															>
																<Td
																	style={{ "-webkit-padding-start": "0" }}
																	colSpan={2}
																>
																	{thaiDateTime(
																		order.order_created_datetime,
																		nowLanguage === "th" ? "th-TH" : "en-US"
																	)}
																</Td>
																<Td
																	style={{ "-webkit-padding-end": "0" }}
																	textAlign={"right"}
																	colSpan={2}
																>
																	{formatValue(
																		order.order_details
																			.split(";")
																			.reduce((total, detail) => {
																				const [, , , price, quantity] =
																					detail.split(":");
																				return (
																					total +
																					parseFloat(price) * parseInt(quantity)
																				);
																			}, 0)
																			.toFixed(2)
																	)}
																</Td>
															</Tr>
															{order.order_details
																.split(";")
																.map((detail, i) => {
																	const [
																		,
																		productName,
																		productNameEn,
																		price,
																		quantity,
																	] = detail.split(":");
																	const countPrice = (
																		parseFloat(price) * parseInt(quantity)
																	).toFixed(2);
																	return (
																		<Tr
																			key={i}
																			fontWeight={"300"}
																			color={textColor}
																		>
																			<Td
																				border={"none"}
																				style={{ WebkitPaddingStart: "0px" }}
																			>
																				{nowLanguage === "th"
																					? productName
																					: productNameEn !== ""
																					? productNameEn
																					: productName}
																			</Td>
																			<Td
																				border={"none"}
																				textAlign={"right"}
																				style={{
																					WebkitPaddingStart: "0px",
																					WebkitPaddingEnd: "0px",
																				}}
																			>
																				{formatValue(price)} x {quantity}
																			</Td>
																			<Td
																				border={"none"}
																				textAlign={"right"}
																				style={{
																					WebkitPaddingStart: "0px",
																					WebkitPaddingEnd: "0px",
																				}}
																			>
																				<Text>{formatValue(countPrice)}</Text>
																			</Td>
																			<Td
																				border={"none"}
																				style={{ WebkitPaddingEnd: "0px" }}
																				w={"1px"}
																			>
																				<Box
																					as={"a"}
																					color={"red.200"}
																					cursor={"pointer"}
																					onClick={() =>
																						handleDelete(detail, order.order_id)
																					}
																				>
																					<TiDelete size={"1.5em"} />
																				</Box>
																			</Td>
																		</Tr>
																	);
																})}
														</>
													))}
												</Tbody>
											</Table>
										</Box>
									</Box>
									<Box
										width={"100%"}
										borderTop={"1px solid #ededed"}
										bgColor={bgCardColor}
										py={2.5}
										pl={2}
										pr={5}
									>
										<SimpleGrid
											columns={2}
											spacing={0}
											fontWeight={"600"}
											color={textSumColor}
										>
											<Text>{nowLanguage === "th" ? "รวม" : "Total"}</Text>
											<Text textAlign={"right"}>
												{formatValue(totalPrice.toFixed(2))}{" "}
												{nowLanguage === "th" ? "บาท" : "baht"}
											</Text>
										</SimpleGrid>
									</Box>
								</>
							)}
						</>
					)}
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default BillDetailModalAdmin;
