import React, { useState } from "react";
import * as QRCode from "qrcode";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { Button, Spinner, Flex } from "@chakra-ui/react";

const DownloadQRCodesButton = ({ dataArray, shopCode }) => {
	// console.log(dataArray);
	const [isLoading, setIsLoading] = useState(false);

	const encodeUnit = (id) => {
		// Convert the ID to a string and concatenate it with the secret key
		const combined = `${id}mySevscanEncodeDecode`;

		// Convert to base64 using btoa
		const encoded = btoa(combined);

		return encoded;
	};

	const handleDownload = async () => {
		setIsLoading(true);

		try {
			if (!dataArray || dataArray.length === 0) {
				console.error("dataArray is empty or undefined");
				setIsLoading(false);
				return;
			}

			const zip = new JSZip();

			const newQrCodeData = await QRCode.toDataURL(
				`${window.location.origin}/demo/${shopCode}`
			);
			const newQrCodeImage = await fetch(newQrCodeData).then((res) =>
				res.blob()
			);
			zip.file(`สำหรับดูเมนูได้อย่างเดียว.png`, newQrCodeImage);

			for (let i = 0; i < dataArray.length; i++) {
				const urlLink = `${window.location.origin}/menu?shop=${
					dataArray[i].code
				}&unit=${encodeUnit(dataArray[i].id)}`;
				// console.log(urlLink);
				const qrCodeData = await QRCode.toDataURL(urlLink);
				const qrCodeImage = await fetch(qrCodeData).then((res) => res.blob());
				zip.file(`${dataArray[i].name}.png`, qrCodeImage);
			}

			const content = await zip.generateAsync({ type: "blob" });
			saveAs(content, "qr_codes.zip");
		} catch (error) {
			console.error("Error generating QR codes and creating ZIP file:", error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Flex align="center" justify="right" mb={3}>
			<Button
				onClick={handleDownload}
				disabled={isLoading}
				position="fixed"
				bottom={4}
				right={28}
				boxShadow="md"
				zIndex={1}
				borderRadius={"full"}
				border={"1.5px solid"}
				borderColor={"cyan.700"}
				color={"cyan.800"}
				bgColor={"#d0e7ec"}
				w={"12em"}
				fontWeight={"400"}
			>
				{isLoading ? <Spinner size="md" /> : "ดาวน์โหลด QR Menu"}
			</Button>
		</Flex>
	);
};

export default DownloadQRCodesButton;
