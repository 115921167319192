import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Swal from "sweetalert2";
import socket from "../config/socket";
import { API_BASE_URL } from "../config/apiConfig";
import {
	Box,
	Flex,
	LinkBox,
	Text,
	Grid,
	ScaleFade,
	Card,
	Image,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalFooter,
	Button,
	Table,
	Tbody,
	Tr,
	Td,
	useToast,
	Spacer,
	useColorModeValue,
	Textarea,
	RadioGroup,
	Radio,
	Stack,
	SimpleGrid,
	Spinner,
} from "@chakra-ui/react";
import { FaPlus, FaMinus } from "react-icons/fa";
import { ModalBody } from "@chakra-ui/react";
import { TiDelete } from "react-icons/ti";
import {
	PiClockClockwiseDuotone,
	PiShoppingBagDuotone,
	PiListDashesDuotone,
	PiCallBellDuotone,
	PiSquaresFourDuotone,
	PiRowsDuotone,
	PiClosedCaptioningDuotone,
} from "react-icons/pi";
import BillDetailModal from "./BillDetailModal";
import ChangeLanguage from "./ChangeLanguage";

function MenuList({ data, demo, employee, setIsOpenRestaurantOrder }) {
	// console.log(data);
	const [nowLanguage, setNowLanguage] = useState(
		localStorage.getItem("language") || "th"
	);

	// console.log(data);
	const { t } = useTranslation();
	const textCard = useColorModeValue("cyan.700", "cyan.400");
	const toast = useToast();
	const [productList, setProductList] = useState();
	const [selectedCategory, setSelectedCategory] = useState();
	const [showProductByCategory, setShowProductByCategory] = useState();
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [quantity, setQuantity] = useState(1);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [basketItems, setBasketItems] = useState([]);
	const [isBasketModalOpen, setIsBasketModalOpen] = useState(false);
	const [billDetail, setBillDetail] = useState([]);
	const [loading, setLoading] = useState(true);
	const modalBodyRef = useRef(null);

	useEffect(() => {
		setProductList(data.productResult);
		setSelectedCategory(data.categoryResult[0]?.id);
	}, [data]);

	useEffect(() => {
		setShowProductByCategory([]);
		const timeout = setTimeout(() => {
			setShowProductByCategory(() => {
				const showCategoryId = selectedCategory; // Accessing id property
				const products = showCategoryId
					? productList?.filter(
							(product) => product.category_id === showCategoryId
					  )
					: productList;
				const sortedProducts = products?.sort((a, b) => a.no - b.no);
				return sortedProducts;
			});
		}, 10);

		return () => clearTimeout(timeout);
	}, [selectedCategory, productList]);

	const handleCategory = (category) => {
		setSelectedCategory(category.id);
	};

	const handleProductClick = (product) => {
		// console.log(product);
		setSelectedProduct(product);
		setQuantity(1);
		setIsModalOpen(true);
	};
	useEffect(() => {
		let timeoutId;
		if (isModalOpen) {
			timeoutId = setTimeout(() => {
				modalBodyRef.current.scrollIntoView({ behavior: "smooth" });
			}, 500);
		}

		return () => clearTimeout(timeoutId);
	}, [isModalOpen]);

	const incrementQuantity = () => {
		setQuantity(quantity + 1);
	};

	const decrementQuantity = () => {
		if (quantity > 1) {
			setQuantity(quantity - 1);
		}
	};

	const addToBasket = () => {
		// console.log("kkk", selectedProduct.choice);
		// Check if a choice is required but not selected
		if (selectedProduct.choice !== "[]" && selectChoiceValue === "") {
			toast({
				title: t("select_items_before_proceeding"),
				position: "top",
				isClosable: true,
				status: "warning",
				variant: "top-accent",
				duration: 5000,
			});
			return;
		}

		// Create a new item with the necessary properties
		const newItem = {
			...selectedProduct,
			quantity: quantity,
			price:
				selectedProduct.choice !== "[]"
					? selectChoiceValue
					: selectedProduct.price,
			name:
				selectedProduct.choice !== "[]"
					? `${selectedProduct.name}(${selectChoiceText.text})`
					: selectedProduct.name,
			name_en:
				selectedProduct.choice !== "[]"
					? `${selectedProduct.name_en}(${selectChoiceText.text_en})`
					: selectedProduct.name_en,
		};
		// console.log(newItem);

		// Update the basket items and close the modal
		setBasketItems((prevItems) => [...prevItems, newItem]);
		setIsModalOpen(false);
		setDisplayChoice("");
		setSelectChoiceValue("");
		setSelectChoiceText({});
	};

	const handleShowBill = async () => {
		setIsModalBillOpen(true);
		setLoading(true);
		setBillDetail([]);
		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/shopping/check_order`,
				{ unitShopResult: data.unitShopResult }
			);
			// console.log(response.data);
			setBillDetail(response.data);
			setLoading(false);
		} catch (error) {
			toast({
				title: t("api_error"),
				description: t("please_call_staff"),
				position: "top",
				isClosable: true,
				status: "error",
				variant: "top-accent",
				duration: 5000,
			});
		}
	};

	const [isModalBillOpen, setIsModalBillOpen] = useState(false);
	const handleCloseBillModal = () => {
		setIsModalBillOpen(false);
	};

	const handleCallStaff = async () => {
		Swal.fire({
			text: t("call_staff"),
			icon: "info",
			showCancelButton: true,
			confirmButtonColor: "#109ebe",
			cancelButtonColor: "#c24a4a",
			cancelButtonText: t("cancel"),
			confirmButtonText: t("confirm"),
			reverseButtons: true,
			showLoaderOnConfirm: true,
			showClass: {
				popup: "swal2-noanimation", // No animation for showing
			},
			hideClass: {
				popup: "", // No animation for hiding
			},
			preConfirm: async (login) => {
				try {
					const response = await axios.post(
						`${API_BASE_URL}/api/shopping/call_staff`,
						{ unitShopResult: data.unitShopResult }
					);
					// console.log(response.data);
					if (response.data.message === "ok") {
						toast({
							title: t("success"),
							position: "top",
							isClosable: true,
							status: "success",
							variant: "top-accent",
							duration: 5000,
						});
					} else if (response.data.message === 401) {
						toast({
							title: t("add_token"),
							position: "top",
							isClosable: true,
							status: "warning",
							variant: "top-accent",
							duration: 5000,
						});
					} else {
						toast({
							title: t("call_staff_error"),
							position: "top",
							isClosable: true,
							status: "warning",
							variant: "top-accent",
							duration: 5000,
						});
					}
				} catch (error) {
					toast({
						title: t("api_error"),
						position: "top",
						isClosable: true,
						status: "error",
						variant: "top-accent",
						duration: 5000,
					});
				}
			},
			allowOutsideClick: () => !Swal.isLoading(),
		});
	};

	const [isLargeStyle, setIsLargeStyle] = useState(false);
	useEffect(() => {
		const savedStyle = localStorage.getItem("large_style");
		if (savedStyle !== null) {
			setIsLargeStyle(savedStyle === "true");
		}
	}, []);
	const toggleStyle = () => {
		setIsLargeStyle((prevState) => {
			const newState = !prevState;
			localStorage.setItem("large_style", newState);
			return newState;
		});
	};

	const [isFixed, setIsFixed] = useState(false);
	const handleScroll = useCallback(() => {
		if (window.scrollY > 100) {
			setIsFixed(true);
		} else {
			setIsFixed(false);
		}
	}, []);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [handleScroll]);

	const [selectChoiceValue, setSelectChoiceValue] = useState("");
	const [selectChoiceText, setSelectChoiceText] = useState({});
	const [displayChoice, setDisplayChoice] = useState("");

	const handleChoiceChange = (value) => {
		const [totalValue, index] = value.split("-");
		const selectedItem = JSON.parse(selectedProduct.choice)[index];

		setDisplayChoice(value);
		setSelectChoiceValue(totalValue);
		setSelectChoiceText({
			text: selectedItem.text,
			text_en: selectedItem.text_en,
		});
	};

	const handleClose = () => {
		setIsModalOpen(false);
		setDisplayChoice("");
		setSelectChoiceValue("");
		setSelectChoiceText({});
	};

	const formatValue = (value) => {
		return value.endsWith(".00") ? parseInt(value) : value;
	};
	const borderTopColor = useColorModeValue(
		"1px solid #ededed",
		"1px solid #5e6a78"
	);
	const bgColor = useColorModeValue("white", "gray.700");

	return (
		<Box fontWeight={"300"} mt={employee ? "-53px" : "0"}>
			<Flex
				px={{ base: 2, md: 3 }}
				py={1}
				width={"100%"}
				zIndex={1}
				bgColor={useColorModeValue("white", "cyan.900")}
			>
				<Text
					fontWeight={300}
					fontSize={"90%"}
					color={useColorModeValue("gray.600", "gray.100")}
				>
					sevscan.com
				</Text>
				<Spacer />
				<ChangeLanguage setNowLanguageCorePage={setNowLanguage} />
			</Flex>
			<Box
				px={{ base: 2, md: 3 }}
				fontWeight={"500"}
				fontSize={"xl"}
				color={"cyan.700"}
			>
				{nowLanguage === "th"
					? data.unitShopResult.shop_name
					: data.unitShopResult.shop_name_en !== ""
					? data.unitShopResult.shop_name_en
					: data.unitShopResult.shop_name}
			</Box>
			<Box>
				<Box px={{ base: 2, md: 3 }}>
					<Flex>
						<Text
							color={employee ? "cyan.700" : "gray.500"}
							fontSize={employee ? "xl" : "md"}
							fontWeight={employee ? "600" : "400"}
						>
							{nowLanguage === "th"
								? data.unitShopResult.unit_name
								: data.unitShopResult.unit_name_en !== ""
								? data.unitShopResult.unit_name_en
								: data.unitShopResult.unit_name}
						</Text>
						<Spacer />
						<Box>
							{isLargeStyle ? (
								<PiSquaresFourDuotone
									size={"1.5em"}
									onClick={toggleStyle}
									color="#0987a0"
									cursor={"pointer"}
								/>
							) : (
								<PiRowsDuotone
									size={"1.5em"}
									onClick={toggleStyle}
									color="#0987a0"
									cursor={"pointer"}
								/>
							)}
						</Box>
					</Flex>
					{data.unitShopResult.recommend !== "" && (
						<Box
							px={2}
							py={1}
							fontSize={"sm"}
							color={"red.500"}
							bgColor={"red.50"}
							borderRadius={"sm"}
							textAlign={"center"}
						>
							{nowLanguage === "th"
								? data.unitShopResult.recommend
								: data.unitShopResult.recommend_en !== ""
								? data.unitShopResult.recommend_en
								: data.unitShopResult.recommend}
						</Box>
					)}
				</Box>
			</Box>
			<Box
				position={isFixed ? "fixed" : "relative"}
				top={isFixed ? 0 : "auto"}
				width="100%"
				transition="position 0.1s ease-in-out"
				overflowX="auto"
				whiteSpace="nowrap"
				sx={{
					"&::-webkit-scrollbar": { display: "none" },
					scrollbarWidth: "none",
					msOverflowStyle: "none",
				}}
				px={{ base: 2, md: 3 }}
				bgColor={"white"}
				_dark={{ bgColor: "gray.800" }}
				zIndex={1}
			>
				<Box display="inline-block">
					<Flex overflowX="auto" gridGap={3} py={isFixed ? 2.5 : 3}>
						{data.categoryResult.map((category, index) => (
							<LinkBox
								key={index}
								onClick={() => handleCategory(category)}
								borderRadius="full"
								cursor="pointer"
								px={5}
								py={2}
								bgColor={
									category.id === selectedCategory ? "cyan.700" : "gray.100"
								}
								_dark={{
									bgColor:
										category.id === selectedCategory ? "cyan.700" : "gray.400",
								}}
							>
								<Text
									color={
										category.id === selectedCategory ? "white" : "gray.600"
									}
									_dark={{
										color: "white",
									}}
									fontWeight={category.id === selectedCategory ? "500" : "400"}
									fontSize={"95%"}
								>
									{nowLanguage === "th"
										? category.name
										: category.name_en !== ""
										? category.name_en
										: category.name}
								</Text>
							</LinkBox>
						))}
					</Flex>
				</Box>
			</Box>
			<ScaleFade initialScale={0.5} in={true}>
				<SimpleGrid
					columns={{
						base: isLargeStyle ? 1 : 2,
						md: isLargeStyle ? 2 : 3,
						lg: isLargeStyle ? (employee ? 2 : 4) : employee ? 3 : 5,
						xl: isLargeStyle ? (employee ? 2 : 5) : employee ? 3 : 6,
					}}
					px={{ base: 2, md: 4 }}
					pb={10}
					gap={{ base: 2, md: 4 }}
					mb={"30px"}
					alignContent="stretch"
					zIndex={0}
					marginTop={isFixed ? 14 : 0}
				>
					{showProductByCategory?.map((product, index) => (
						<Box h={"full"} key={index}>
							{isLargeStyle ? (
								<CardLargeStyle
									t={t}
									product={product}
									onClick={handleProductClick}
									formatValue={formatValue}
									textCard={textCard}
									nowLanguage={nowLanguage}
								/>
							) : (
								<CardSmallStyle
									t={t}
									product={product}
									onClick={handleProductClick}
									textCard={textCard}
									formatValue={formatValue}
									nowLanguage={nowLanguage}
								/>
							)}
						</Box>
					))}
				</SimpleGrid>
			</ScaleFade>

			{!demo && (
				<Box
					position="fixed"
					bottom="0px"
					width={"100%"}
					py={0.5}
					borderTop={borderTopColor}
					bgColor={bgColor}
				>
					<Grid
						templateColumns={
							employee
								? "repeat(2, 1fr)"
								: data.unitShopResult.show_call_staff === 1
								? "repeat(3, 1fr)"
								: "repeat(2, 1fr)"
						}
						gap={2}
						pt={0.5}
					>
						<Flex
							direction="column"
							alignItems="center"
							justifyContent="center"
						>
							<Box
								as="button"
								onClick={() => setIsBasketModalOpen(true)}
								textAlign="center"
							>
								<Box display="inline-block" position="relative">
									<PiShoppingBagDuotone size={"2em"} color="#0987a0" />
									{basketItems.length > 0 && (
										<Box
											bgColor="red.500"
											color="white"
											borderRadius="full"
											position="absolute"
											top="0px"
											right="-10px"
											fontSize="0.8em"
											width="20px"
											height="20px"
											textAlign="center"
											lineHeight="20px"
										>
											{basketItems.length}
										</Box>
									)}
								</Box>
								<Text color="#0987a0" fontSize={"80%"} mt={-2}>
									{t("basket")}
								</Text>
							</Box>
						</Flex>
						<Flex
							direction="column"
							alignItems="center"
							justifyContent="center"
							display={"none"}
						>
							<Box as="button" onClick={handleShowBill} textAlign="center">
								<Box display="inline-block">
									<PiClockClockwiseDuotone size={"2em"} color="#0987a0" />
								</Box>
								<Text color="#0987a0" fontSize="80%" mt={-2}>
									{t("status")}
								</Text>
							</Box>
						</Flex>
						{employee ? (
							<Flex
								direction="column"
								alignItems="center"
								justifyContent="center"
							>
								<Box
									as="button"
									onClick={() => setIsOpenRestaurantOrder(false)}
									textAlign="center"
								>
									<Box display="inline-block">
										<PiClosedCaptioningDuotone size={"2em"} color="#f46666" />
									</Box>
									<Text color="#f46666" fontSize="80%" mt={-2}>
										กลับ
									</Text>
								</Box>
							</Flex>
						) : (
							<Flex
								direction="column"
								alignItems="center"
								justifyContent="center"
							>
								<Box as="button" onClick={handleShowBill} textAlign="center">
									<Box display="inline-block">
										<PiListDashesDuotone size={"2em"} color="#0987a0" />
									</Box>
									<Text color="#0987a0" fontSize="80%" mt={-2}>
										{t("bill")}
									</Text>
								</Box>
							</Flex>
						)}
						{employee || data.unitShopResult.show_call_staff !== 1 ? (
							""
						) : (
							<Flex
								direction="column"
								alignItems="center"
								justifyContent="center"
							>
								<Box as="button" onClick={handleCallStaff} textAlign="center">
									<Box display="inline-block">
										<PiCallBellDuotone size={"2em"} color="#0987a0" />
									</Box>
									<Text color="#0987a0" fontSize="80%" mt={-2}>
										{t("call_staff")}
									</Text>
								</Box>
							</Flex>
						)}
					</Grid>
				</Box>
			)}

			{/* Modal */}
			<Modal isOpen={isModalOpen} onClose={handleClose} isCentered size={"sm"}>
				<ModalOverlay />
				<ModalContent p={2} bgColor={"#ffffff00"} boxShadow={"none"}>
					<ModalCloseButton
						bgColor={useColorModeValue("white", "gray.600")}
						borderRadius={"full"}
						mt={2}
						mr={1}
						zIndex={1000}
					/>
					<ImageWithLoading selectedProduct={selectedProduct} />
					<ModalBody
						bgColor={useColorModeValue("white", "gray.600")}
						borderBottomRadius={!demo ? "0" : "1em"}
					>
						<Text fontWeight={"600"} fontSize={"120%"} pt={2}>
							{nowLanguage === "th"
								? selectedProduct?.name
								: selectedProduct?.name_en !== ""
								? selectedProduct?.name_en
								: selectedProduct?.name}
						</Text>
						<Text
							pb={4}
							fontWeight={"300"}
							color={useColorModeValue("gray.700", "gray.200")}
						>
							{nowLanguage === "th"
								? selectedProduct?.description
								: selectedProduct?.description_en !== ""
								? selectedProduct?.description_en
								: selectedProduct?.description}
						</Text>
						<Flex direction={"column"}>
							{selectedProduct?.choice !== "[]" ? (
								<RadioGroup onChange={handleChoiceChange} value={displayChoice}>
									<Stack direction="column">
										{selectedProduct?.choice &&
											JSON.parse(selectedProduct?.choice).map((item, index) => {
												const totalValue = (
													parseFloat(selectedProduct.price) +
													parseFloat(item.number)
												).toFixed(2);
												const radioValue = `${totalValue}-${index}`;
												return (
													<Radio value={radioValue} key={index}>
														<Text as="span" whiteSpace="nowrap">
															{nowLanguage === "th"
																? item?.text
																: item?.text_en !== ""
																? item?.text_en
																: item?.text}{" "}
															<Text as="span" color={textCard}>
																{formatValue(totalValue)} {t("baht")}
															</Text>
														</Text>
													</Radio>
												);
											})}
									</Stack>
								</RadioGroup>
							) : (
								<Text color={textCard}>
									{formatValue(selectedProduct?.price)} {t("baht")}
								</Text>
							)}

							<Flex
								justifyContent="flex-end"
								display={!demo ? "block" : "none"}
							>
								<Flex alignItems="center" mt={4}>
									<Button
										colorScheme="gray"
										variant="ghost"
										onClick={decrementQuantity}
										mr={2}
									>
										<FaMinus size={"10px"} />
									</Button>
									<Text px={3}>{quantity}</Text>
									<Button
										colorScheme="gray"
										variant="ghost"
										onClick={incrementQuantity}
										ml={2}
									>
										<FaPlus size={"10px"} />
									</Button>
								</Flex>
							</Flex>
						</Flex>
					</ModalBody>
					<ModalFooter
						borderBottomRadius={"1em"}
						bgColor={useColorModeValue("white", "gray.600")}
						padding={"1em 1.2em"}
						ref={modalBodyRef}
						display={!demo ? "block" : "none"}
					>
						<Button
							borderRadius={"full"}
							border={"1.5px solid"}
							borderColor={"cyan.700"}
							color={"cyan.800"}
							bgColor={"#d0e7ec"}
							size={"md"}
							fontWeight={"md"}
							onClick={addToBasket}
							w={"full"}
							isDisabled={selectedProduct?.out_of_stock === "1"}
						>
							{selectedProduct?.out_of_stock === "1"
								? t("out_of_stock")
								: t("add")}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			<BasketModal
				t={t}
				isOpen={isBasketModalOpen}
				onClose={() => setIsBasketModalOpen(false)}
				basketItems={basketItems}
				setBasketItems={setBasketItems}
				unitShopResult={data.unitShopResult}
				formatValue={formatValue}
				nowLanguage={nowLanguage}
			/>

			<BillDetailModal
				isOpen={isModalBillOpen}
				onClose={handleCloseBillModal}
				billDetail={billDetail}
				loading={loading}
				modalSize={"full"}
				nowUnitName={data.unitShopResult.unit_name}
				nowUnitNameEn={data.unitShopResult.unit_name_en}
				nowLanguage={nowLanguage}
			/>
		</Box>
	);
}

function ImageWithLoading({ selectedProduct }) {
	const [isLoading, setIsLoading] = useState(true);

	return (
		<Box
			borderTopRadius={"1em"}
			bgColor={useColorModeValue("white", "gray.600")}
			minH={"200px"}
			position="relative"
		>
			{isLoading && (
				<Spinner
					size="sm"
					thickness="2px"
					speed="0.65s"
					color="cyan.500"
					position="absolute"
					top="50%"
					left="50%"
					transform="translate(-50%, -50%)"
				/>
			)}

			<Image
				boxSize={"full"}
				objectFit="cover"
				src={`${API_BASE_URL}/image/product/large/${selectedProduct?.image}`}
				alt={selectedProduct?.name}
				borderTopRadius={"1em"}
				onLoad={() => setIsLoading(false)}
			/>
		</Box>
	);
}

const CardLargeStyle = ({
	t,
	product,
	onClick,
	textCard,
	formatValue,
	nowLanguage,
}) => {
	const [isLoading, setIsLoading] = useState(true);
	return (
		<Card
			borderRadius={"1em"}
			bgColor={"#d0e7ec4a"}
			shadow={"none"}
			pos={"relative"}
			cursor="pointer"
			onClick={() => onClick(product)}
		>
			<Flex>
				<Box position="relative">
					{isLoading && (
						<Spinner
							size="sm"
							thickness="2px"
							speed="0.65s"
							color="cyan.500"
							position="absolute"
							top="50%"
							left="50%"
							transform="translate(-50%, -50%)"
						/>
					)}
					<Image
						boxSize="80px"
						objectFit="cover"
						src={`${API_BASE_URL}/image/product/small/${product.image}`}
						alt="Product Image"
						borderRadius={"0.5em"}
						onLoad={() => setIsLoading(false)}
					/>
					{product.out_of_stock === "1" && (
						<Box
							position="absolute"
							top={0}
							left={0}
							right={0}
							bottom={0}
							backgroundColor="rgba(0, 0, 0, 0.5)" // Adjust opacity as needed
							display="flex"
							justifyContent="center"
							alignItems="center"
							borderTopRadius={"1em"}
							borderBottomRadius={"0.5em"}
						>
							<Text
								color="white"
								fontWeight="bold"
								fontSize="sm"
								textAlign={"center"}
							>
								{t("out_of_stock")}
							</Text>
						</Box>
					)}
				</Box>
				<Text fontWeight={"300"} pl={3} pt={1}>
					{nowLanguage === "th"
						? product.name
						: product.name_en !== ""
						? product.name_en
						: product.name}
				</Text>
				<Text color={textCard} pos={"absolute"} bottom={"1"} right={"3"}>
					{formatValue(product.price)} {t("baht")}
				</Text>
			</Flex>
		</Card>
	);
};

const CardSmallStyle = ({
	t,
	product,
	onClick,
	textCard,
	formatValue,
	nowLanguage,
}) => {
	const [isLoading, setIsLoading] = useState(true);
	return (
		<Card
			pb={3}
			alignItems="center"
			as={"button"}
			onClick={() => onClick(product)}
			height="100%"
			width={"100%"}
			display="flex"
			flexDirection="column"
			justifyContent="space-between"
			position="relative"
			bgColor={"#d0e7ec4a"}
			shadow={"none"}
			borderRadius={"1em"}
		>
			<Box position="relative" width="100%" pb="100%">
				{isLoading && (
					<Spinner
						size="sm"
						thickness="2px"
						speed="0.65s"
						color="cyan.500"
						position="absolute"
						top="50%"
						left="50%"
						transform="translate(-50%, -50%)"
					/>
				)}
				<Image
					position="absolute"
					top={0}
					left={0}
					width="100%"
					height="100%"
					objectFit="cover"
					src={`${API_BASE_URL}/image/product/small/${product.image}`}
					alt="Product Image"
					borderTopRadius={"1em"}
					onLoad={() => setIsLoading(false)}
				/>
				{product.out_of_stock === "1" && (
					<Box
						position="absolute"
						top={0}
						left={0}
						right={0}
						bottom={0}
						backgroundColor="rgba(0, 0, 0, 0.5)" // Adjust opacity as needed
						display="flex"
						justifyContent="center"
						alignItems="center"
						borderTopRadius={"1em"}
						borderBottomRadius={"0.5em"}
					>
						<Text
							color="white"
							fontWeight="bold"
							fontSize="xl"
							textAlign={"center"}
						>
							{t("out_of_stock")}
						</Text>
					</Box>
				)}
			</Box>

			<Box bottom={2} pt={4}>
				<Text fontWeight={"300"}>
					{nowLanguage === "th"
						? product.name
						: product.name_en !== ""
						? product.name_en
						: product.name}
				</Text>
			</Box>
			<Flex w={"full"} px={3} pt={2}>
				<Text>{t("price")}</Text>
				<Spacer />
				<Text color={textCard}>
					{formatValue(product.price)} {t("baht")}
				</Text>
			</Flex>
		</Card>
	);
};

const BasketModal = ({
	t,
	isOpen,
	onClose,
	basketItems,
	setBasketItems,
	unitShopResult,
	formatValue,
	nowLanguage,
}) => {
	const toast = useToast();
	const bgColor = useColorModeValue("gray.50", "gray.600");
	const [comment, setComment] = useState("");
	const handleCommentChange = (event) => {
		const trimmedComment = event.target.value.trim();
		setComment(trimmedComment);
	};

	const totalPrice = basketItems
		.reduce((acc, item) => acc + item.price * item.quantity, 0)
		.toFixed(2);

	const handlePushOrder = async () => {
		// console.log(basketItems);
		if (basketItems.length === 0) {
			toast({
				title: t("add_items_before_order"),
				position: "top",
				isClosable: true,
				status: "warning",
				variant: "top-accent",
				duration: 5000,
			});
		} else {
			onClose();
			Swal.fire({
				text: t("send_order"),
				icon: "info",
				showCancelButton: true,
				confirmButtonColor: "#109ebe",
				cancelButtonColor: "#c24a4a",
				cancelButtonText: t("cancel"),
				confirmButtonText: t("confirm"),
				reverseButtons: true,
				showLoaderOnConfirm: true,
				showClass: {
					popup: "swal2-noanimation", // No animation for showing
				},
				hideClass: {
					popup: "", // No animation for hiding
				},
				preConfirm: async (login) => {
					const response = await axios.post(
						`${API_BASE_URL}/api/shopping/push_order`,
						{
							unitShopResult,
							basketItems,
							comment,
						}
					);
					// console.log("handlePushOrder", response.data);

					if (response.data.message === "ok") {
						setBasketItems([]);
						setComment("");
						toast({
							title: t("success"),
							position: "top",
							isClosable: true,
							status: "success",
							variant: "top-accent",
							duration: 5000,
						});
						socket.emit("update_order_list", unitShopResult.shop_id);
					} else if (response.data.message === "expire") {
						toast({
							title: t("code_expired"),
							description: t("please_call_staff"),
							position: "top",
							isClosable: true,
							status: "warning",
							variant: "top-accent",
							duration: 5000,
						});
					} else if (response.data.message === 401) {
						toast({
							title: t("add_token"),
							position: "top",
							isClosable: true,
							status: "warning",
							variant: "top-accent",
							duration: 5000,
						});
					} else {
						toast({
							title: t("send_order_error"),
							description: t("please_call_staff"),
							position: "top",
							isClosable: true,
							status: "error",
							variant: "top-accent",
							duration: 5000,
						});
					}
				},
				allowOutsideClick: () => !Swal.isLoading(),
			});
		}
	};
	const handleDelete = (index) => {
		const updatedBasketItems = [...basketItems];
		updatedBasketItems.splice(index, 1); // Remove the item at the specified index
		setBasketItems(updatedBasketItems);
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="md" isCentered>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader pl={3}>{t("basket")}</ModalHeader>
				<ModalBody p={2}>
					<Table
						variant="simple"
						overflowX="auto"
						fontSize={"sm"}
						fontWeight={"300"}
						bgColor={bgColor}
					>
						<Tbody>
							{basketItems.map((item, index) => (
								<Tr key={index}>
									<Td p={1}>
										<Image
											boxSize="50px"
											objectFit="cover"
											src={`${API_BASE_URL}/image/product/small/${item.image}`}
											alt={item.name}
											borderRadius={"5px"}
										/>
										{nowLanguage === "th"
											? item.name
											: item.name_en !== ""
											? item.name_en
											: item.name}
									</Td>
									<Td p={1} pr={3} textAlign="right">
										{formatValue(item.price)} x {item.quantity} <br />={" "}
										{formatValue((item.price * item.quantity).toFixed(2))}
									</Td>
									<Td p={1} w={"1px"}>
										<Box
											as={"a"}
											color={"red.200"}
											onClick={() => handleDelete(index)}
										>
											<TiDelete size={"1.5em"} cursor={"pointer"} />
										</Box>
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
					<Textarea
						mt={3}
						placeholder="เพิ่มข้อความ"
						fontSize={"sm"}
						value={comment}
						onChange={handleCommentChange}
						display={"none"}
					/>
				</ModalBody>
				<ModalFooter justifyContent={"center"} px={4}>
					<Flex direction="column" alignItems="center" w={"full"}>
						<Box color={"cyan.700"} fontWeight={"400"} textAlign={"center"}>
							{t("total")} {formatValue(totalPrice)} {t("baht")}
						</Box>
						<Flex justifyContent="center" w={"full"} gap={3} mt={5}>
							<Button
								flex={1}
								borderRadius={"full"}
								color={"#ffeeee"}
								bgColor={"#c24a4a"}
								fontWeight={"md"}
								onClick={onClose}
								_hover={"none"}
							>
								{t("close")}
							</Button>
							<Button
								flex={1}
								borderRadius={"full"}
								color={"#e2faff"}
								bgColor={"cyan.700"}
								fontWeight={"md"}
								onClick={handlePushOrder}
								_hover={"none"}
							>
								{t("order")}
							</Button>
						</Flex>
					</Flex>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default MenuList;
